import { SVGProps } from 'react';

const SvgLine12 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Line_svg__a_12)">
      <path d="M7.5 5h4a1 1 0 1 1 0 2h-10a1 1 0 0 1 0-2h6Z" fill="#1A1A1A" />
    </g>
    <defs>
      <clipPath id="Line_svg__a_12">
        <path fill="currentColor" transform="translate(.5)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

SvgLine12.defaultProps = {
  width: 12,
  height: 12,
};
export default SvgLine12;
