import clsx from 'clsx';
import { ReactNode } from 'react';
import { NavLink, To } from 'react-router-dom';
import { overrideTailwindClasses } from 'tailwind-override';

type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: ReactNode;
  pinned?: boolean;
  small?: boolean;
  large?: boolean;
  className?: string;
  bordered?: boolean;
  to?: To;
  end?: boolean;
  anchor?: boolean;
  href?: string;
  countBadge?: number;
};

const IconButton = ({
  icon,
  small,
  pinned,
  className,
  bordered,
  large,
  to,
  end,
  anchor,
  href,
  countBadge,
  ...props
}: IconButtonProps) => {
  const sizeClassName = small ? 'w-6 h-6' : large ? 'w-10 h-10' : 'w-8 h-8';
  const backgroundClassName = pinned
    ? 'bg-orange-600/10 enabled:hover:bg-opacity-40'
    : bordered
    ? 'border bg-grey-900 enabled:hover:border-none enabled:hover:bg-grey-800'
    : 'enabled:hover:bg-grey-800';

  if (to) {
    return (
      <NavLink
        to={to}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
          }
        }}
        end={end}
        className={overrideTailwindClasses(
          clsx(
            'grid shrink-0 place-content-center rounded-md  border-grey-600 text-grey-100',
            sizeClassName,
            backgroundClassName,
            className,
            !!props.disabled && 'cursor-not-allowed border-grey-800  text-grey-400',
            !props.disabled && 'hover:border-none hover:bg-grey-800',
          ),
        )}
      >
        {icon}
      </NavLink>
    );
  }

  if (anchor) {
    return (
      <a
        href={href}
        className={clsx(
          'grid shrink-0 place-content-center rounded-md border-grey-600 text-grey-100 hover:border-none hover:bg-grey-800',
          sizeClassName,
          backgroundClassName,
          className,
        )}
        target="_blank"
        rel="noreferrer"
      >
        {icon}
      </a>
    );
  }
  return (
    <button
      {...props}
      className={clsx(
        'grid shrink-0 place-content-center rounded-md border-grey-600 text-grey-100 disabled:cursor-not-allowed disabled:border-grey-800 disabled:text-grey-400',
        sizeClassName,
        backgroundClassName,
        className,
        { relative: countBadge },
      )}
    >
      {icon}
      {countBadge && (
        <div className="absolute -top-1.5 -left-1.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border border-grey-900 bg-orange-600 text-3xs font-medium text-black">
          {countBadge > 99 ? '99+' : countBadge}
        </div>
      )}
    </button>
  );
};

export default IconButton;

IconButton.defaultProps = {
  bordered: true,
};
