import { SVGProps } from 'react';

const SvgCheck12 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.293 1.293 4 7.586 1.707 5.293A1 1 0 0 0 .293 6.707l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0-1.414-1.414Z"
      fill="currentColor"
    />
  </svg>
);

SvgCheck12.defaultProps = {
  width: 12,
  height: 12,
};
export default SvgCheck12;
