import clsx from 'clsx';
import { ButtonHTMLAttributes, HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react';
import { NavLink, To } from 'react-router-dom';
import { overrideTailwindClasses } from 'tailwind-override';
import useMobile from '../hooks/useMobile';
import Loader from './Loader';

type ButtonProps = {
  preset:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'nav'
    | 'nav-collapsed'
    | 'danger'
    | 'link'
    | 'selected';
  children: ReactNode;
  fullWidth: boolean;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  to?: To;
  href?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  isLoading?: boolean;
  progress?: number;
  className?: string;
  activeClassName?: string;
  active?: boolean;
  end?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>;

const Button = ({
  preset,
  children,
  fullWidth,
  icon,
  iconPosition,
  to,
  isLoading,
  progress,
  className,
  activeClassName,
  active,
  end,
  href,
  target,
  ...props
}: ButtonProps) => {
  const presetStyle = useMemo(() => {
    if (props.disabled)
      return clsx(
        'text-grey-300 justify-center gap-4 cursor-not-allowed',
        preset === 'primary'
          ? 'bg-grey-800 border border-grey-800'
          : preset === 'ghost'
          ? 'text-grey-500 focus-visible:bg-none'
          : preset === 'link'
          ? 'text-grey-500 gap-2'
          : 'text-grey-500 border border-grey-500',
      );
    if (preset === 'primary')
      return 'bg-orange-600 text-grey-1000 justify-center gap-4 hover:bg-orange-700 focus-visible:bg-orange-800';
    if (preset === 'danger')
      return 'bg-red-600 text-grey-1000 justify-center gap-4 mb-2 hover:bg-red-700 focus-visible:bg-red-800';
    if (preset === 'secondary')
      return 'border text-white border-grey-700 justify-center gap-4 mb-2 focus-visible:bg-grey-1000 hover:bg-grey-800';
    if (preset === 'ghost')
      return 'text-white justify-center gap-4 mb-2 hover:bg-grey-800 focus-visible:bg-none';
    if (preset === 'nav') return 'text-grey-200 hover:text-white gap-4';
    if (preset === 'nav-collapsed') return 'text-grey-200 hover:text-white flex-col gap-2 px-0';
    if (preset === 'link') return 'text-orange-600 hover:text-orange-700 gap-2 justify-center';
    if (preset === 'selected')
      return 'border text-white border-orange-600 justify-center gap-4 mb-2 focus-visible:bg-grey-1000 hover:bg-grey-800';
    return '';
  }, [preset, props.disabled]);

  const isMobile = useMobile();
  const widthClass = fullWidth ? 'w-full' : '';
  const commonClass = 'flex items-center rounded-md py-3.5 px-4 text-sm font-medium';
  const combinedClassName = overrideTailwindClasses(
    clsx(commonClass, presetStyle, widthClass, className, active && activeClassName),
  );

  if (to) {
    return (
      <NavLink
        onClick={props.disabled ? (e) => e.preventDefault() : isMobile ? props?.onClick : undefined}
        to={to}
        end={end}
        className={({ isActive }) =>
          clsx(combinedClassName, isActive && (activeClassName || 'bg-grey-900 text-white'))
        }
      >
        {icon && (!iconPosition || iconPosition === 'left') && <span>{icon}</span>}
        {children}
        {icon && iconPosition === 'right' && <span>{icon}</span>}
      </NavLink>
    );
  }

  if (href) {
    return (
      <a href={href} target={target} className={combinedClassName}>
        {icon && (!iconPosition || iconPosition === 'left') && <span>{icon}</span>}
        {children}
        {icon && iconPosition === 'right' && <span>{icon}</span>}
      </a>
    );
  }

  return (
    <button
      className={combinedClassName}
      {...props}
      style={
        isLoading && progress !== undefined
          ? {
              backgroundImage: `linear-gradient(90deg, rgb(64, 64, 64) ${(
                Math.min(progress, 1) * 100
              ).toFixed(0)}%, transparent ${(Math.min(progress, 1) * 100).toFixed(0)}%)`,
            }
          : undefined
      }
    >
      {isLoading && progress === undefined && <Loader color="black" />}
      {isLoading && progress !== undefined && children}
      {!isLoading && icon && (!iconPosition || iconPosition === 'left') && <span>{icon}</span>}
      {!isLoading && children}
      {icon && iconPosition === 'right' && <span>{icon}</span>}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  preset: 'primary',
  fullWidth: true,
};
