import { Navigate, Route, Routes } from 'react-router-dom';
import { AppStateInit } from './AppStateInit';
import { useAuth } from './auth/AuthProvider';
import { useAppDispatch } from './store';
import { setFromPath } from './redux/appSlice';
import { fetchSites } from './waittimes/queueManagementSlice';
import QueueManagement from './waittimes/QueueManagement';

const AuthRoutes = () => {

  const auth = useAuth();
  const dispatch = useAppDispatch();

  if (!auth?.session?.access_token) {
    dispatch(setFromPath(location.pathname + location.search));
    return <Navigate to={`/login`} replace />;
  }
  dispatch(fetchSites());

  return (
    <AppStateInit>
      <Routes>
        <Route path="*" element={<QueueManagement />} />
        <Route path="/" element={<QueueManagement />} />
      </Routes>
    </AppStateInit>
  )
};

export default AuthRoutes;