import clsx from 'clsx';
import { ChangeEvent, ReactNode, forwardRef } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';
import SvgLine12 from '../icons/Line12';
import SvgUnion12 from '../icons/Union';
import useMobile from 'src/hooks/useMobile';

export type CheckboxProps = {
  name: string;
  label?: string;
  checked?: boolean;
  indeterminate?: boolean;
  children?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  labelRight?: boolean;
  rounded?: boolean;
  labelClassName?: string;
  disabled?: boolean;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      name,
      label,
      labelRight,
      indeterminate,
      rounded,
      labelClassName,
      disabled,
      ...props
    },
    ref,
  ) => {
    const isMobile = useMobile();
    const checkboxBaseClass = 'w-6 h-6 rounded-md';
    const checkboxOverlayClass =
      'pointer-events-none invisible absolute top-0 left-0 grid place-content-center bg-orange-600';
    return (
      <div
        className={overrideTailwindClasses(clsx('align-center gap-1 flex items-center', isMobile ? "flex-row mb-0" : "flex-col mb-2" ,className))}
      >
        {label && labelRight && (
          <label
            htmlFor={name}
            className={overrideTailwindClasses(
              clsx('cursor-pointer text-sm text-white', labelClassName),
            )}
          >
            {label}
          </label>
        )}
        <div className="align-center relative flex  items-center">
          <input
            ref={ref}
            id={name}
            name={name}
            type="checkbox"
            className={clsx(
              checkboxBaseClass,
              'peer relative cursor-pointer  border border-grey-800 bg-grey-1000 ',
              { 'rounded-full': rounded },
              { 'cursor-not-allowed': disabled },
            )}
            {...props}
          />
          <div
            className={clsx(
              checkboxBaseClass,
              'pointer-events-none absolute top-0 left-0 grid place-content-center bg-orange-600',
              !(indeterminate && !props.checked) && 'invisible',
            )}
          >
            <SvgLine12 className="text-grey-1000" />
          </div>
          <div
            className={clsx(checkboxBaseClass, checkboxOverlayClass, 'peer-checked:visible', {
              'rounded-full': rounded,
            })}
          >
            <SvgUnion12 className="text-grey-1000" />
          </div>
        </div>
        {label && !labelRight && (
          <label
            htmlFor={name}
            className={overrideTailwindClasses(
              clsx('ml-3 cursor-pointer text-sm text-white', labelClassName),
            )}
          >
            {label}
          </label>
        )}
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';

export default Checkbox;
