import { SVGProps } from 'react';

const SvgUnion12 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.293.293 4 6.586 1.707 4.293A1 1 0 0 0 .293 5.707l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.293.293Z"
      fill="currentColor"
    />
  </svg>
);

SvgUnion12.defaultProps = {
  width: 12,
  height: 9,
};
export default SvgUnion12;
