import { RootState } from "src/store";
import { AxiosRequestHeaders } from 'axios';
import { selectSession } from '../auth/authSelectors';

export type ApiResponse<T> = {
  content: T;
  result: {
    success: true;
    httpCode: number;
  };
};

export type ApiResult<T> = {
  isFetched?: boolean;
  isLoading: boolean;
  error: string | null;
  data: T;
};

export type QueryApiColumn = {
  name: string;
  columnType: string;
  dataType: string;
};

export type QueryApiResponse = {
  columns: QueryApiColumn[];
  rows: any[];
};

export function getAuthorizationHeaders(
  state: RootState,
): AxiosRequestHeaders {
  const session = selectSession(state);
  const token = session?.access_token
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return { Authorization: `Bearer ${token}` };
}

export function clearApiResult<T>(seed: T): ApiResult<T> {
  return {
    isFetched: false,
    isLoading: false,
    error: null,
    data: seed,
  };
}