type SeparatorProps = {
  text: string;
};

const Separator = ({ text }: SeparatorProps) => (
  <div className="relative w-full">
    <hr className="my-9 border-grey-800" />
    <span className="text-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-grey-900 px-2 text-2xs tracking-widest text-grey-200">
      {text}
    </span>
  </div>
);

export default Separator;
