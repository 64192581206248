import { ReactNode, useEffect } from 'react';
import { isModuleLoaded } from './redux/appSelectors';
import { AppModule, appInit } from './redux/appSlice';
import { useAppDispatch, useAppSelector } from './store';

type AppStateInitProps = {
  children?: ReactNode;
};
export const AppStateInit = ({ children }: AppStateInitProps) => {
  const dispatch = useAppDispatch();
  const isAppInitialized = useAppSelector((state) => isModuleLoaded(state, AppModule.APP));
  useEffect(() => {
    if (!isAppInitialized) {
      dispatch(appInit());
    }
  }, [isAppInitialized, dispatch]);
  return <>{children}</>;
};
