import { AppStartListening } from 'src/listenerMiddleware';
import { AppModule, appInit, initializeAppModule } from './appSlice';

const addAppListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: appInit,
    effect: (_action, api) => {
      api.dispatch(initializeAppModule(AppModule.APP));
    },
  });
};
export default addAppListeners;
