import { TypeOptions } from 'react-toastify';
import { ToastPosition } from 'react-toastify/dist/types';
import SVGClose12 from '../icons/Close12';
import SvgDanger16 from '../icons/Danger16';
import SvgSuccess16 from '../icons/Success16';

type ToastProps = {
  type?: TypeOptions;
  defaultClassName?: string;
  position?: ToastPosition;
  rtl?: boolean;
};

const switchToastClass = (toastType?: TypeOptions) => {
  switch (toastType) {
    case 'success':
      return 'bg-teal-600 text-grey-1000';
    case 'error':
      return 'bg-red-600';
  }
};

export const closeButtonSelect = ({ type }: ToastProps) => {
  const switchCloseButtonClass = () => {
    switch (type) {
      case 'success':
        return 'text-grey-1000';
      case 'error':
        return 'text-white';
    }
  };

  return (
    <div className="ml-4 flex flex-col justify-center">
      <SVGClose12 className={switchCloseButtonClass()} />
    </div>
  );
};

export const iconSelect = ({ type }: ToastProps) => {
  switch (type) {
    case 'success':
      return <SvgSuccess16 className="text-grey-1000" />;
    case 'error':
      return <SvgDanger16 className="text-white" />;
  }
};

export const toastClassNameSelect = (toast?: ToastProps) =>
  `${switchToastClass(toast?.type)} text-sm p-4 min-h-0 rounded-md boxShadow-alert flex mt-1`;
