import clsx from 'clsx';
import { ChangeEventHandler, ReactNode, RefObject, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { overrideTailwindClasses } from 'tailwind-override';
import Loader from '../components/Loader';
import Button from './Button';
import Error from './Error';
import Label from './Label';

export type InputTextProps = {
  size?: 'small' | 'medium';
  name: string;
  error?: FieldError;
  placeholder?: string;
  type?: string;
  label?: string;
  icon?: ReactNode;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitDisabled?: boolean;
  cancelDisabled?: boolean;
  noMargin?: boolean;
  className?: string;
  boxClassName?: string;
  readOnly?: boolean;
  isLoading?: boolean;
  inlineLabel?: boolean;
  suffix?: string;
} & (
  | {
      multiline: true;
      onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    }
  | {
      multiline?: false;
      onChange?: ChangeEventHandler<HTMLInputElement>;
    }
);

const InputText = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputTextProps>(
  (
    {
      size,
      name,
      placeholder,
      type,
      error,
      label,
      icon,
      disabled,
      value,
      defaultValue,
      onChange,
      onSubmit,
      onCancel,
      submitDisabled,
      cancelDisabled,
      multiline,
      noMargin,
      className,
      inlineLabel,
      boxClassName,
      isLoading,
      suffix,
      ...props
    },
    ref,
  ) => {
    const inputClassName = overrideTailwindClasses(
      clsx(
        !onSubmit
          ? 'h-10 rounded-md border placeholder-grey-300 border-grey-700 shadow-preset1 bg-grey-1000 px-4 no-underline caret-orange-600 outline-0 focus:border-orange-600 text-sm'
          : 'placeholder-grey-300 bg-grey-1000  text-sm no-underline caret-orange-600 outline-0 focus:border-orange-600',
        size === 'medium' ? 'py-3' : 'py-2',
        error && 'border-red-600',
        icon && 'pl-12',
        disabled ? 'text-grey-500' : 'text-white',
        className,
      ),
    );

    return (
      <div
        className={clsx(
          'relative flex gap-2',
          boxClassName,
          inlineLabel ? 'flex-row items-center' : 'flex-col',
          !noMargin && 'mb-6',
          onSubmit &&
            'rounded-md border border-grey-700 bg-grey-1000 px-2 shadow-preset1 outline-0 focus-within:border-orange-600',
        )}
      >
        <Label label={label} name={name} />
        {icon && <span className="absolute grid h-full w-12 place-content-center ">{icon}</span>}

        {multiline ? (
          <textarea
            ref={ref as RefObject<HTMLTextAreaElement>}
            className={clsx('h-full whitespace-pre-wrap', inputClassName)}
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
        ) : (
          <>
            {suffix ? (
              <div className="relative inline-block w-full">
                <input
                  ref={ref as RefObject<HTMLInputElement>}
                  id={name}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  className={inputClassName}
                  value={value}
                  defaultValue={defaultValue}
                  onChange={onChange}
                  disabled={disabled}
                  {...props}
                />
                <span className="absolute top-2 right-4 text-sm text-grey-300">{suffix}</span>
              </div>
            ) : (
              <input
                ref={ref as RefObject<HTMLInputElement>}
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                className={inputClassName}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={disabled}
                {...props}
              />
            )}
          </>
        )}
        <div
          className={clsx(
            'absolute right-2 mt-auto mb-auto flex h-fit shrink-0 flex-row gap-2',
            multiline && 'bottom-2',
            !multiline && 'bottom-0 top-0 ',
          )}
        >
          {onCancel && (
            <Button
              onClick={onCancel}
              preset="secondary"
              className={`mb-0 h-6 w-14`}
              disabled={cancelDisabled}
            >
              Cancel
            </Button>
          )}
          {onSubmit && (
            <Button
              onClick={onSubmit}
              type="submit"
              className={`mb-0 h-6 w-11`}
              disabled={submitDisabled}
            >
              Send
            </Button>
          )}
        </div>
        {!!isLoading && (
          <span className="absolute grid h-full w-full content-center justify-end	pr-3 ">
            <Loader />
          </span>
        )}
        <Error {...error} />
      </div>
    );
  },
);
InputText.displayName = 'InputText';

export default InputText;

InputText.defaultProps = {
  type: 'text',
  size: 'small',
};
