import { selectSSOMethod } from 'src/auth/authSelectors';
import { SSOMethod } from 'src/auth/authSlice';
import { PROVIDERS } from 'src/auth/loginMethods';
import configDev from 'src/config/dev';
import { useAppSelector } from 'src/store';
import Button from './Button';

const PrimarySSOProvider = () => {
  const ssoMethod = useAppSelector(selectSSOMethod) as Required<SSOMethod>;
  const src = PROVIDERS[ssoMethod.kind]?.src;
  const url = `https://api.livereachmedia.com/api/v1/login/connections/${ssoMethod.id}?realm=${configDev.REALM}`;
  return (
    <a className="w-full" href={url}>
      <Button preset="secondary">
        <img src={src} className="my-1 h-6 w-[70px]" alt="" />
      </Button>
    </a>
  );
};

export default PrimarySSOProvider;
