import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import queueManagementSlice from './waittimes/queueManagementSlice';
import authSlice from './auth/authSlice';
import { listenerMiddleware } from './listenerMiddleware';
import appSlice from 'src/redux/appSlice';

const appReducer = combineReducers({
  [queueManagementSlice.name]: queueManagementSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [appSlice.name]: appSlice.reducer,
});

const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === authSlice.actions.logout.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
