import ConfigType from './types';

const isIP = (str: string) => {
  const cong =
    /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
  return cong.test(str);
};

const isDeployPreview = (hostname: string) =>
  hostname.includes('netlify.app');

const configDev: ConfigType = {
  BASE_URL: 'https://api.livereachmedia.com/api/v1',
  // BASE_URL: 'http://127.0.0.1:4729/api/v1',
  get REALM() {
    const hostname = window.location.hostname;
    if (hostname !== 'localhost' && !isDeployPreview(hostname) && !isIP(hostname)) {
      return window.location.hostname;
    }
    return '';
    // return 'quickquack.livereach.ai';
  },
};

export default configDev;
