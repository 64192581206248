import { RootState } from './../store';
import { SSOMethod } from './authSlice';

export const isLoggedIn = (state: RootState) => {
  return !!state.auth.session?.access_token;
};

export const selectTwoFactorAccessToken = (state: RootState) => {
  return state.auth['twoFactor']?.confirmation?.access_token;
};

export const selectTwoFactorTimeLeft = (state: RootState) => {
  const expires_at = state.auth['twoFactor']?.confirmation?.expires_at || 0;
  return expires_at - Date.now();
};

export const is2FANeeded = (state: RootState) => {
  return !!state.auth['twoFactor']?.confirmation && selectTwoFactorTimeLeft(state) > 0;
};

export const selectSession = (state: RootState) => {
  return state.auth.session;
};

export const isProcessing = (state: RootState) => {
  return state.auth.isProcessing;
};

export const selectSSOMethod = (state: RootState): SSOMethod | undefined => {
  return state.auth.ssoMethods[0];
};
export const selectFormError = (field: string) => (state: RootState) =>
  state.auth.formErrors.find((formError) => formError.field === field);

export const selectTwoFactorUserAttemptData = (state: RootState) => {
  return state.auth.twoFactor?.attemptData;
};
