import { SVGProps } from 'react';

const SvgCheck16 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 7.556 6.286 12 14 4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgCheck16.defaultProps = {
  width: 16,
  height: 16,
};
export default SvgCheck16;
