import { CanceledError } from 'axios';
import { toast } from 'react-toastify';
import { AppStartListening } from '../listenerMiddleware';
import axiosInstance from '../services/axios';
import { ApiResponse, getAuthorizationHeaders, QueryApiResponse } from '../services/types';
import { Site, Zone, ZoneOverride } from './types';
import { closeLane, fetchLiveLaneStatus, fetchLiveWaitTimes, fetchSites, fetchZoneOverrides, fetchZones, liveLaneStatusFetchError, liveLaneStatusFetched, liveLaneStatusFetching, liveWaitTimesFetchError, liveWaitTimesFetched, liveWaitTimesFetching, openLane, setSelectedSiteId, sitesFetchError, sitesFetched, sitesFetching, updateZoneLaneStatus, updateZoneOverride, updateZoneWaitTime, zoneLaneStatusFetched, zoneOverridesFetchError, zoneOverridesFetched, zoneOverridesFetching, zoneWaitTimesFetched, zonesFetchError, zonesFetched, zonesFetching } from './queueManagementSlice';


const addQueueManagementListeners = (startListening: AppStartListening) => {
  startListening({
    actionCreator: fetchSites,
    effect: async (_action, api) => {
      api.unsubscribe();
      try {
        api.dispatch(sitesFetching());
        const response = await axiosInstance.get<ApiResponse<Site[]>>(`/sites`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        const sites = response.data;
        api.dispatch(sitesFetched(sites));
        if (sites.content.length > 0) {
          api.dispatch(setSelectedSiteId(4533));
        }
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          api.dispatch(sitesFetchError(err.toString()));
          toast.error('failed to get sites');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: fetchZones,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        api.dispatch(zonesFetching());
        const response = await axiosInstance.get<ApiResponse<Zone[]>>(`sites/${action.payload}/zones?config=true`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        const zones = response.data;
        api.dispatch(zonesFetched(zones));
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          api.dispatch(zonesFetchError(err.toString()));
          toast.error('failed to get zones');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: fetchZoneOverrides,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        api.dispatch(zoneOverridesFetching());
        const response = await axiosInstance.get<ApiResponse<ZoneOverride[]>>(`sites/${action.payload}/zones/wait_time_overrides`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        const data = response.data;
        api.dispatch(zoneOverridesFetched(data));
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          api.dispatch(zoneOverridesFetchError(err.toString()));
          toast.error('failed to get Zone Overrides');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: fetchLiveWaitTimes,
    effect: async (_action, api) => {
      api.unsubscribe();
      try {
        api.dispatch(liveWaitTimesFetching());
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(`locations/3851/metrics/live?metrics=waitTime&zoneOverride=true`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        api.dispatch(liveWaitTimesFetched(response.data.content)); // Extract the 'data' property from the 'ApiResponse' object
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          console.log(err);
          api.dispatch(liveWaitTimesFetchError(err.toString()));
          toast.error('failed to get Live Wait Times');
        }
      } finally {
        api.subscribe();
      }
    },
  });
  
  startListening({
    actionCreator: fetchLiveLaneStatus,
    effect: async (_action, api) => {
      api.unsubscribe();
      try {
        api.dispatch(liveLaneStatusFetching());
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(`locations/3851/metrics/live/lane_status`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        api.dispatch(liveLaneStatusFetched(response.data.content)); // Extract the 'data' property from the 'ApiResponse' object
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          api.dispatch(liveLaneStatusFetchError(err.toString()));
          toast.error('failed to get Live Lane Status');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: updateZoneLaneStatus,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        // api.dispatch(zoneLiveStatusFetching());
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(`zones/${action.payload}/metrics/live/lane_status`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        api.dispatch(zoneLaneStatusFetched([response.data.content, action.payload])); // Extract the 'data' property from the 'ApiResponse' object
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          // api.dispatch(zoneLiveStatusFetchError(err.toString()));
          toast.error('failed to get Zone Lane Status');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: updateZoneWaitTime,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        // api.dispatch(zoneWaitTimeFetching());
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(`zones/${action.payload}/metrics/live?metrics=waitTime&zoneOverride=true`, {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });

        api.dispatch(zoneWaitTimesFetched([response.data.content, action.payload])); // Extract the 'data' property from the 'ApiResponse' object
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          // api.dispatch(zoneWaitTimeFetchError(err.toString()));
          toast.error('failed to get Zone Wait Time');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: updateZoneOverride,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        await axiosInstance.patch<ApiResponse<any>>(`zones/${action.payload.zone_id}/wait_time_override`, 
        {
          closed: action.payload.closed,
          override_waittime: action.payload.override_waittime || null,
          manual_override: action.payload.manual_override,
          status: action.payload.status || null,
          expires: action.payload.expires || null,
        },
        {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });
        toast.success('Success');
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          toast.error('failed to update');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: openLane,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        await axiosInstance.patch<ApiResponse<any>>(`zones/${action.payload}/wait_time_override`, 
        {
          closed: false,
          manual_override: false,
        },
        {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });
        toast.success('Lane Open!');
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          toast.error('Failed to open');
        }
      } finally {
        api.subscribe();
      }
    },
  });

  startListening({
    actionCreator: closeLane,
    effect: async (action, api) => {
      api.unsubscribe();
      try {
        await axiosInstance.patch<ApiResponse<any>>(`zones/${action.payload}/wait_time_override`, 
        {
          closed: true,
          manual_override: true,
        },
        {
          headers: getAuthorizationHeaders(api.getState()),
          signal: api.signal,
        });
        toast.success('Lane Closed');
      } catch (err: any) {
        if (!(err instanceof CanceledError)) {
          toast.error('Failed to close');
        }
      } finally {
        api.subscribe();
      }
    },
  });
};

export default addQueueManagementListeners;