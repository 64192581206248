import { SVGProps } from 'react';

const SvgDelete16 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 2a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v2.1h2.75a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75H2.75A.75.75 0 0 1 2 7.85v-3a.75.75 0 0 1 .75-.75H5.5V2ZM7 4.1h2V2.75H7V4.1ZM4.15 9.75a.75.75 0 0 1 .75.75v1.777c0 .69.428.973.65.973h4.9c.221 0 .65-.282.65-.973V10.5a.75.75 0 0 1 1.5 0v1.777c0 1.213-.825 2.473-2.15 2.473h-4.9c-1.326 0-2.15-1.26-2.15-2.473V10.5a.75.75 0 0 1 .75-.75ZM3.5 7.1V5.6h9v1.5h-9Z"
      fill="currentColor"
    />
  </svg>
);

SvgDelete16.defaultProps = {
  width: 16,
  height: 16,
};
export default SvgDelete16;
