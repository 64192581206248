import { ReactNode, useEffect } from 'react';
import { useMemo } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }: { children: ReactNode }) => {
  const mount = useMemo(() => document.getElementById('root'), []);
  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    mount?.appendChild(el);
    return () => {
      mount?.removeChild(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(children, el);
};

export default Portal;
