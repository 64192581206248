import clsx from 'clsx';
import { useMemo } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

type DotProps = {
  size: 1 | 2;
  color: string;
  className?: string;
};

const Dot = ({ size, color, className }: DotProps) => {
  const styleSize = useMemo(() => {
    if (size === 2) return 'h-2 w-2';
    if (size === 1) return 'h-1 w-1';
    return '';
  }, [size]);

  return (
    <div
      className={overrideTailwindClasses(
        clsx(`mx-2 ${styleSize} ${color} rounded-full`, className),
      )}
    />
  );
};

export default Dot;

Dot.defaultProps = {
  size: 2,
  color: 'bg-grey-400',
};
