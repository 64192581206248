import { SVGProps } from 'react';

const SvgDanger16 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1Zm1-3H7V4h2v5Z"
      fill="currentColor"
    />
  </svg>
);

SvgDanger16.defaultProps = {
  width: 16,
  height: 16,
};
export default SvgDanger16;
