import * as yup from 'yup';
import { DigitFields, LoginFields } from './types';

export const loginPanelSchema = yup.object({
  [LoginFields.username]: yup.string().min(8).required(),
  [LoginFields.password]: yup.string().min(8).required(),
});

export const twoFactorPanelSchema = yup.object({
  [DigitFields.digit1]: yup.number().min(0).max(9).required(),
  [DigitFields.digit2]: yup.number().min(0).max(9).required(),
  [DigitFields.digit3]: yup.number().min(0).max(9).required(),
  [DigitFields.digit4]: yup.number().min(0).max(9).required(),
  [DigitFields.digit5]: yup.number().min(0).max(9).required(),
  [DigitFields.digit6]: yup.number().min(0).max(9).required(),
});
