import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import Dot from 'src/components/Dot';
import InputText from 'src/components/InputText';
import LogoSubtitle from 'src/components/LogoSubtitle';
import axiosInstance from 'src/services/axios';
import LoginWrapper from './LoginWrapper';

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [hasSent, setSent] = useState(false);

  const handleReset = useCallback(() => {
    setLoading(true);
    const f = async (email: string) => {
      try {
        setLoading(true);
        const req = await axiosInstance.post(`/users/password/forgot`, {
          email,
        });
        if (req.status >= 200 && req.status < 300) {
          setSent(true);
        }
      } catch (err: any) {
        toast.error('Failed to reset password');
      } finally {
        setLoading(false);
      }
    };
    f(email);
    return false;
  }, [email]);

  return (
    <>
      <div className="flex h-full min-h-screen w-full flex-col items-center justify-start bg-grey-900 md:justify-center md:bg-grey-1000">
        {hasSent && (
          <LoginWrapper>
            <LogoSubtitle
              label="You should receive an email with further instructions. Remember to check your spam
              inbox."
            />
            <Button preset="secondary" to="/login">
              Back
            </Button>
          </LoginWrapper>
        )}
        {!hasSent && (
          <LoginWrapper>
            <LogoSubtitle label="Enter your email below and you will receive a link to reset your password." />
            <div className="flex h-full w-full flex-grow flex-col justify-between md:flex-grow-0">
              <InputText
                name="email"
                size="medium"
                label="Email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Button type="submit" disabled={!email} isLoading={isLoading} onClick={handleReset}>
                Submit
              </Button>
            </div>
            <Button preset="secondary" to="/login">
              Cancel
            </Button>
          </LoginWrapper>
        )}
        <div className="flex items-center justify-center pb-4 md:pt-4">
          <a
            className="text-xs text-grey-200 underline"
            href="https://livereach.ai/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <Dot size={2} />
          <a
            className="text-xs text-grey-200 underline"
            href="https://livereach.ai/resources/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <Dot size={2} />
          <a
            className="text-xs text-grey-200 underline"
            href="https://livereach.ai/demo"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
