import SvgDanger16 from 'src/icons/Danger16';
import SvgSuccess16 from 'src/icons/Success16';

type BoolToLabelProps = {
  bool: boolean;
  label?: string;
  labels: { true: string; false: string };
  hideFalse?: boolean;
  showFalseLabel?: boolean;
};

const BoolToLabel = ({ bool, label, labels, hideFalse, showFalseLabel }: BoolToLabelProps) => (
  <div className="flex items-center gap-2">
    {bool ? (
      <>
        <SvgSuccess16 className="text-teal-600" />
        {!label && <div>{labels.true}</div>}
        {label && <div>{label}</div>}
      </>
    ) : (
      <>
        {!hideFalse ? (
          <>
            <SvgDanger16 className="text-red-600" />
            {!label && <div>{labels.false}</div>}
            {label && <div>{label}</div>}
          </>
        ) : (
          <>
            {!showFalseLabel && '-'}
            {showFalseLabel && label}
          </>
        )}
      </>
    )}
  </div>
);

BoolToLabel.defaultProps = {
  labels: { true: 'Yes', false: 'No' },
};
export default BoolToLabel;
