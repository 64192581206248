type LoginWrapperProps = {
  children: JSX.Element | JSX.Element[];
};

const LoginWrapper = ({ children }: LoginWrapperProps) => {
  return (
    <div
      className="flex h-full w-full flex-grow-0 flex-col items-center rounded-md border-grey-800  
                bg-grey-900 px-4 pb-4 text-white md:mt-4 md:h-auto md:w-[684px] md:border md:px-28 md:pb-10"
    >
      {children}
    </div>
  );
};

export default LoginWrapper;
