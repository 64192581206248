import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

type PageHeaderProps = PropsWithChildren<{
  title?: string;
  className?: string;
}>;

const PageHeader = ({ title, children, className }: PageHeaderProps) => (
  <div
    className={overrideTailwindClasses(
      clsx('mt-4 flex flex-col justify-between md:mt-10 md:flex-row', className),
    )}
  >
    <div className="mx-4 mb-0 text-3xl font-medium text-white md:mx-10 md:text-4xl">{title}</div>
    {children}
  </div>
);

export default PageHeader;
