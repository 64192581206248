import { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="M67.34 15.9a3.55 3.55 0 1 0-5 5l25.51 25.54H3.53a3.56 3.56 0 0 0 0 7.11h84.32L62.3 79.06a3.62 3.62 0 0 0 0 5 3.53 3.53 0 0 0 5 0l31.6-31.6a3.47 3.47 0 0 0 0-5Z" fill="#fff"/>
  </svg>
)
export default SvgArrowRight
