/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import InputText from '../components/InputText';
import LogoSubtitle from '../components/LogoSubtitle';
import PrimarySSOProvider from '../components/PrimarySSOProvider';
import Separator from '../components/Separator';
import LoginWrapper from './LoginWrapper';
import { isProcessing, selectSSOMethod } from './authSelectors';
import { login } from './authSlice';
import { loginPanelSchema } from './schemas';
import { LoginFields, LoginValues } from './types';

const LoginPanel = () => {
  const isLoading = useAppSelector(isProcessing);
  const ssoMethod = useAppSelector(selectSSOMethod);
  const apiFormErrors = useAppSelector((state) => state.auth.formErrors);
  const credentials = { username: '', password: '' }
  const dispatch = useAppDispatch();
  const {
    register,
    setFocus,
    handleSubmit,
    formState: { isValid, errors },
    setError,
  } = useForm<LoginValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(loginPanelSchema),
    defaultValues: {
      [LoginFields.username]: credentials.username,
      [LoginFields.password]: credentials.password,
    },
  });
  useEffect(() => {
    apiFormErrors.forEach(({ field, message }) => {
      if (field === 'username' || field == 'password') {
        setError(field, { message });
      }
    });
  }, [apiFormErrors, setError]);
  const onSubmit = async ({ username, password, isRememberMe }: LoginValues) => {
    dispatch(login({ password, username, isRememberMe }));
  };

  useEffect(() => {
    setFocus(LoginFields.username);
  }, [setFocus]);

  return (
    <LoginWrapper>
      {ssoMethod ? (
        <>
          <LogoSubtitle label="Login with one of the following" />
          <PrimarySSOProvider />
          <Separator text="OR" />
        </>
      ) : (
        <LogoSubtitle />
      )}
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('username')}
          size="medium"
          label="Email"
          placeholder="Enter your email"
          error={errors.username}
        />
        <InputText
          {...register('password')}
          size="medium"
          label="Password"
          placeholder="Enter your password"
          type="password"
          error={errors.password}
        />
        <div className="mb-6 flex justify-between">
          <Checkbox {...register('isRememberMe')} label="Remember me" />
          <Link to="/forgot" className="text-sm text-orange-600 hover:underline">
            Forgot your password?
          </Link>
        </div>
        {/* TODO: remove */}
        <Button type="submit" disabled={!isValid} isLoading={isLoading}>
          Login
        </Button>
      </form>
    </LoginWrapper>
  );
};

export default LoginPanel;
