import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectZoneOverrides, selectZoneOverridesFetched, selectZones, selectZonesFetched } from "./queueManagementSelectors";
import { ZoneOverride } from "./types";
import BoolToLabel from 'src/components/BoolToLabel';
import SvgCheck16 from "src/icons/Check16";
import IconButton from "src/components/IconButton";
import SvgDelete16 from "src/icons/Delete16";
import { closeLane, openLane, updateZoneOverride } from "./queueManagementSlice";
import Button from "src/components/Button";

import { formatInTimeZone } from 'date-fns-tz';
import { BasicOption } from 'src/components/Dropdown';
import Dropdown from 'src/components/Dropdown';
import Checkbox from "src/components/Checkbox";
import SvgArrowRight from "src/icons/ArrowRight";
import SvgCancel from "src/icons/Cancel";
import { toast } from "react-toastify";
import { selectSession } from "src/auth/authSelectors";
import axiosInstance from "src/services/axios";
import { ApiResponse, QueryApiResponse } from "src/services/types";
import useMobile from "src/hooks/useMobile";

function stringToExpiresAt(str: string) {
  const currentTime = new Date();
  const minutesToAdd = parseInt(str, 10);
  const newTime = new Date(currentTime.getTime() + minutesToAdd * 60000); // Convert minutes to milliseconds

  return newTime;
}

const VestibuleList = () => {

  const zones = useAppSelector(selectZones);
  const zoneOverridesFetched = useAppSelector(selectZoneOverridesFetched);
  const zoneOverrides = useAppSelector(selectZoneOverrides);
  const zonesFetched = useAppSelector(selectZonesFetched);
  const vestibuleZones = useMemo(() => zones.filter((zone: any) => zone.occupancy_enabled === true), [zones]);
  const dispatch = useAppDispatch();
  const authSession = useAppSelector(selectSession);
  const isMobile = useMobile();
  // console.log(vestibuleZones);

  const [localOverrides, setLocalOverrides] = useState<ZoneOverride[]>([]);
  const [editing, setEditing] = useState<Set<number>>(new Set<number>());
  const [expiresMap, setExpiresMap] = useState<{[key: number]: string}>({});
  const expireOptions: BasicOption[] = useMemo(() => {
    return [
      { label: '-', value: '' },
      { label: '30 minutes', value: '30' },
      { label: '1 hour', value: '60' },
      { label: '3 hours', value: '180' },
      { label: '24 hours', value: '1440' },
    ];
  }, []);

  const onChange = async (item: ZoneOverride, key: keyof ZoneOverride, e: any) => {
    setLocalOverrides((prev: ZoneOverride[]) => {
      const index = prev.findIndex((zo: ZoneOverride) => zo.id === item.id);
      if (index !== -1) {
        if (key === 'closed') {
          const updatedZo = { ...prev[index], [key]: e, manual_override: e };
          const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
          return newOverrides;
        } else {
          const updatedZo = { ...prev[index], [key]: e };
          const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
          return newOverrides;
        }
      }
      return prev;
    });

    if (key === 'closed' && e === true) {
      dispatch(closeLane(item.zone_id));
      setEditing((prev) => {
        const newSet = new Set(prev);
        newSet.delete(item.id);
        return newSet;
      });
    } else if (key === 'closed' && e === false) {
      dispatch(openLane(item.zone_id));
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // if (!item.manual_override && !item.closed) {
        try {
          const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(
            `/zones/${item.zone_id}/metrics/live/lane_status`,
            { headers: { Authorization: `Bearer ${authSession?.access_token}` } },
          );
          const current_occupancy = response.data.content.rows[0][0];
          const current_status = response.data.content.rows[0][1];
          setLocalOverrides((prev: ZoneOverride[]) => {
            const index = prev.findIndex((zo: ZoneOverride) => zo.id === item.id);
            if (index !== -1) {
              const updatedZo = { ...prev[index], current_occupancy, current_status };
              const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
              return newOverrides;
            }
            return prev;
          });
        } catch {
          toast.error('failed to fetch lane status');
        }
      // }
      setEditing((prev) => {
        const newSet = new Set(prev);
        newSet.delete(item.id);
        return newSet;
      });
    } else {
      setEditing((prev) => {
        const newSet = new Set(prev);
        newSet.add(item.id);
        return newSet;
      });
    }
  };

  const updateItem = async (item: ZoneOverride) => {
    // console.log(item);
    dispatch(updateZoneOverride(item));
    
    if (!item.manual_override && !item.closed) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(
          `/zones/${item.zone_id}/metrics/live/lane_status`,
          { headers: { Authorization: `Bearer ${authSession?.access_token}` } },
        );
        const current_occupancy = response.data.content.rows[0][0] || 0;
        const current_status = response.data.content.rows[0][1];

        setLocalOverrides((prev: ZoneOverride[]) => {
          const index = prev.findIndex((zo: ZoneOverride) => zo.id === item.id);
          if (index !== -1) {
            const updatedZo = { ...prev[index], current_occupancy, current_status };
            const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
            return newOverrides;
          }
          return prev;
        });        
      } catch {
        toast.error('failed to fetch lane status');
      }
    }
    setEditing((prev) => {
      const newSet = new Set(prev);
      newSet.delete(item.id);
      return newSet;
    });
  }

  const resetChanges = async (item: ZoneOverride) => {
    const index = zoneOverrides.findIndex((zo: ZoneOverride) => zo.id === item.id);
    if (index != -1) {
      if (!zoneOverrides[index].manual_override && !zoneOverrides[index].closed) {
        try {
          const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(
            `/zones/${item.zone_id}/metrics/live/lane_status`,
            { headers: { Authorization: `Bearer ${authSession?.access_token}` } },
          );
          const current_occupancy = response.data.content.rows[0][0];
          const current_status = response.data.content.rows[0][1];
          const i = localOverrides.findIndex((zo: ZoneOverride) => zo.id === item.id);
          setLocalOverrides((prev: ZoneOverride[]) => {
            const updatedZo = { ...prev[index], current_occupancy, current_status };
            const newOverrides = [...prev.slice(0, i), updatedZo, ...prev.slice(i + 1)];
            return newOverrides;
          });
        } catch {
          toast.error('failed to fetch zone wait time');
        }
      } else {
        setLocalOverrides((prev: ZoneOverride[]) => {
          const i = localOverrides.findIndex((zo: ZoneOverride) => zo.id === item.id);
          const updatedZo = zoneOverrides[index];
          const newOverrides = [...prev.slice(0, i), updatedZo, ...prev.slice(i + 1)];
          return newOverrides;
        });
      }
    }
    setEditing((prev) => {
      const newSet = new Set(prev);
      newSet.delete(item.id);
      return newSet;
    });
  }

  useEffect(() => {
    if (zoneOverridesFetched && vestibuleZones.length > 0) {
      const tmpList: ZoneOverride[] = []
      const newMap: {[key: number]: string} = {};
      zoneOverrides.forEach((override: any) => {
        if (vestibuleZones.filter((zone: any) => override.zone_id === zone.id).length !== 0) {
          // const zo: ZoneOverride = {...zoneOverrides.filter((override: any) => override.zone_id === zone.id)[0]};
          // const zo = zoneOverrides.filter((override: any) => override.zone_id === zone.id)[0];
          const zo: ZoneOverride = {...override};
          if (zo.expires) {
            let timeLeft = (new Date(zo.expires).getTime() - new Date().getTime());
            if (timeLeft < 0) {
              zo.manual_override = false;
              zo.closed = false;
            }
            timeLeft = timeLeft / 60000;
            if (timeLeft > 0) {
              if (timeLeft < 30) {
                newMap[zo.id] = '30';
              }
              else if (timeLeft < 60) {
                newMap[zo.id] = '60';
              }
              else if (timeLeft < 180) {
                newMap[zo.id] = '180';
              }
              else if (timeLeft < 1440) {
                newMap[zo.id] = '1440';
              }
            }
          }
          tmpList.push(zo);
          }
        });
        setExpiresMap(newMap);
        setLocalOverrides(tmpList);
    }
  }, [zoneOverridesFetched, vestibuleZones, zoneOverrides]);

  const statusOptions = useMemo(() => [
    { label: 'Open Low', value: 'Open Low' },
    { label: 'Open Full', value: 'Open Full' },
  ], []);

  if (!zoneOverridesFetched || !zonesFetched) {
    return <></>;
  }

  return (
    <div className="p-2 xl:p-10 border-b border-grey-800">
      {!isMobile && (
        <div className="xl:flex hidden gap-2 xl:gap-0 flex-grow flex-col xl:flex-row justify-between xl:justify-start mb-6">
          <div className="mb-6 text-2xl font-medium text-white xl:text-3xl xl:w-[15%] 2xl:w-[20%] flex justify-center xl:justify-start">Lanes</div>
          <div className="mb-6 text-2xl font-medium text-white xl:text-3xl xl:w-1/3 2xl:w-[48%] flex justify-center xl:justify-start">Current Occupancy</div>
          <div className="mb-6 text-2xl font-medium text-white xl:text-3xl xl:w-1/3 2xl:w-[40%] flex justify-center xl:justify-start">Manage</div>
        </div>
      )}
      {( isMobile && (
        <div className="my-6 text-2xl font-medium text-white w-full flex justify-center">Screening Lanes</div>
      ))}
      <div className="flex justify-between gap-2 flex-col text-white">
        {(localOverrides.length > 0 && zones.length > 0) && (
          <div className="flex flex-col gap-2">
            {localOverrides.map((zone: any, index: number) => (
              <div key={index} className="flex gap-4 xl:gap-2 xl:mb-6 flex-col xl:flex-row justify-between rounded-md border border-grey-700 p-4 xl:p-0 xl:border-none">
                <div className='flex gap-4 items-center xl:w-96 justify-between'>
                  <div className="flex flex-col gap-2 text-white justify-center">
                    <span>{zones.filter((z) => z.id === zone.zone_id)[0]?.name}</span>
                  </div>
                  <div className="flex flex-row gap-4 items-center">
                    <BoolToLabel
                        bool={!zone.closed}
                        labels={{ true: 'Open', false: 'Closed' }}
                    />
                  </div>
                  { zone.current_status == 'Open Full' && !zone.closed && !zone.manual_override && (
                    <div className="flex h-16 w-16 items-center bg-green-600" />
                  )}
                  { zone.current_status == 'Open Low' && !zone.closed && !zone.manual_override && (
                    <div className="flex h-16 w-16 items-center bg-green-600 p-2">
                      <SvgArrowRight/>
                    </div>
                  )}
                  { zone.closed && (
                    <div className="flex h-16 w-16 items-center bg-red-600 p-2">
                      <SvgCancel />
                    </div>
                  )}
                  { zone.manual_override && !zone.closed && zone.status == 'Open Low' && (
                    <div className="flex h-16 w-16 items-center bg-green-600 p-2">
                      <SvgArrowRight />
                      </div>
                  )}
                  { zone.manual_override && !zone.closed && zone.status == 'Open Full' && (
                    <div className="flex h-16 w-16 items-center bg-green-600" />
                  )}
                </div>
                <div className='flex gap-4 items-center xl:w-1/6 justify-center'>
                  <div className='flex text-2xl font-medium text-white xl:text-3xl'>
                    {isMobile && 'Current Occupancy: '}
                    {zone.current_occupancy || '0' }
                  </div>
                </div>
                <div className='flex gap-4 xl:gap-2 flex-col xl:flex-row items-center justify-end'>
                  <div className="flex flex-row gap-4 items-center">
                    {!zone.closed ? (
                      <Button className="h-10 w-full xl:w-24" preset="primary" name="closed" onClick={() => onChange(zone, 'closed', true)}>
                        Close
                      </Button>
                    ) : (
                      <Button className="h-10 w-full xl:w-24" preset="primary" name="closed" onClick={() => onChange(zone, 'closed', false)}>
                        Open
                      </Button>
                    )}
                    <Checkbox name="manual_override" label='Override?' labelRight disabled={zone.closed} checked={zone.manual_override} onChange={({ target: { checked } }) => {!zone.closed && onChange(zone, 'manual_override',checked);}} />
                  </div>
                  {/* <div className="flex flex-row gap-4 items-center">
                    <Checkbox name="manual_override" label='Override?' labelRight disabled={zone.closed} checked={zone.manual_override} onChange={({ target: { checked } }) => {!zone.closed && onChange(zone, 'manual_override',checked);}} />
                  </div> */}
                  <div className="flex flex-row gap-4 items-center">
                    <div className="flex flex-col xl:flex-row gap-2 xl:gap-4 xl:items-center items-end">
                      {statusOptions.map((option) => (
                        <div className="flex flex-row xl:flex-col items-center gap-1 mb-0 xl:mb-2 text-sm" key={option.value}>
                          <label htmlFor={option.value} className="peer-checked:text-orange-600 text-center">
                            {option.label}
                          </label>
                          <input
                            type="radio"
                            key={option.value}
                            disabled={!zone.manual_override}
                            checked={zone.status === option.value}
                            onChange={() => onChange(zone, 'status', option.value)}
                            className="peer h-6 w-6 cursor-pointer appearance-none rounded-full border border-grey-700 bg-grey-1000 checked:border-8 checked:border-orange-600"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="w-56  self-center h-full">
                      <Dropdown
                        options={expireOptions}
                        disabled={!zone.manual_override}
                        // withPortal
                        onChange={(value: any) => {
                          setExpiresMap((prev) => {
                            const newMap = { ...prev };
                            newMap[zone.id] = value;
                            return newMap;
                          });
                          if (value === '') {
                            onChange(zone, 'expires', undefined);
                            return;
                          }
                          onChange(zone, 'expires', stringToExpiresAt(value));
                        }}
                        title={zone.expires ? formatInTimeZone(zone.expires, zones.filter((z) => z.id === zone.zone_id)[0].timezone, 'PPp'): '-'}
                        label={
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap text-grey-200">
                              <span className='flex'>Valid Until: {zone.expires ? formatInTimeZone(zone.expires, zones.filter((z) => z.id === zone.zone_id)[0].timezone, 'p'): '-'}</span>
                            {/* <span className="text-white">Expires In: {expiresMap[zone.id] ? expireOptions.filter(x => x.value === expiresMap[zone.id])[0].label : '-'}</span> */}
                          </div>
                        }
                        value={`${expiresMap[zone.id] || ''}`}
                      />
                    </div>
                  </div>
                  {(!isMobile || ( isMobile && editing.has(zone.id))) && (
                    <div className="flex gap-2 flex-row items-center justify-center">
                      <IconButton
                        onClick={() => resetChanges(zone)}
                        disabled={!editing.has(zone.id)}
                        icon={<SvgDelete16 />}
                        type="button"
                        className=""
                      />
                      <IconButton
                        onClick={() => updateItem(zone)}
                        disabled={!editing.has(zone.id)}
                        className="bg-orange-600 disabled:bg-grey-800 disabled:text-grey-300"
                        icon={<SvgCheck16 className="text-grey-1000" />}
                        type="button"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          )}
      </div>
    </div>
  );
}

export default VestibuleList;