import { SVGProps } from 'react';

const SvgLogo265 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 45" {...props}>
    <g data-name="LR AI Logo BG">
      <text
        data-name="LiveReach AI"
        transform="translate(32)"
        fill="#ff9903"
        fontSize={37}
        fontFamily="Inter"
        fontWeight={500}
      >
        <tspan x={0} y={36}>
          {'Liv'}
        </tspan>
        <tspan y={36} letterSpacing=".02em">
          {'e'}
        </tspan>
        <tspan y={36} fill="currentColor" fontWeight={700} letterSpacing="-.02em">
          {'R'}
        </tspan>
        <tspan y={36} fill="currentColor" fontWeight={700}>
          {'each AI'}
        </tspan>
      </text>
      <g data-name="Group 2586">
        <g data-name="Group 2512">
          <path data-name="Path 1404" d="M0 36.049h19.915L9.958 24.187Z" fill="#ff9903" />
          <path
            data-name="Path 1405"
            d="M0 9.022v23.431l9.958-11.715 9.957-11.716Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

SvgLogo265.defaultProps = {
  width: 265,
  height: 45,
};
export default SvgLogo265;
