import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// import AuthRoutes from './AuthRoutes';
import AuthProvider from './auth/AuthProvider';
import ForgotPassword from './auth/ForgotPassword';
import Login from './auth/Login';
import ResetPassword from './auth/ResetPassword';
import { store } from './store';
import AuthRoutes from './AuthRoutes';
import { closeButtonSelect, toastClassNameSelect, iconSelect } from './components/ToastUtils';
import { ToastContainer } from 'react-toastify';

const App = () => {
  // const auth = useAuth();
  // const dispatch = useAppDispatch();
  // if (!auth?.session?.access_token) {
  //   dispatch(setFromPath(location.pathname + location.search));
  //   return <Navigate to={`/login`} replace />;
  // }
  return (
  <>
    <ToastContainer
      position="top-center"
      autoClose={4000}
      hideProgressBar={true}
      closeOnClick
      rounded-md
      closeButton={closeButtonSelect}
      icon={iconSelect}
      toastClassName={toastClassNameSelect}
    />
    <Provider store={store}>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/forgot/:token" element={<ResetPassword />} />
          <Route path="*" element={<AuthRoutes />} />
        </Routes>
      </AuthProvider>
    </Provider>
  </>
  );
};

export default App;

