//@TODO rename file to authStorage.ts
import { SessionState } from './authSlice';

export const STORAGE_KEY = 'lrm_session';

const saveSessionToDefinedStorage = (data: SessionState, storage: Storage = localStorage) => {
  const lrm_session = btoa(JSON.stringify(data));
  storage.setItem(STORAGE_KEY, lrm_session);
};

const getSessionFromAnyStorage = (): SessionState | undefined => {
  const storedValue = sessionStorage.getItem(STORAGE_KEY) || localStorage.getItem(STORAGE_KEY);
  try {
    if (storedValue) return JSON.parse(atob(storedValue));
    return undefined;
  } catch (e) {
    return undefined;
  }
};

const clearSessionInStorage = () => {
  localStorage.removeItem(STORAGE_KEY);
  sessionStorage.removeItem(STORAGE_KEY);
};

export const load = getSessionFromAnyStorage;
export const save = saveSessionToDefinedStorage;
export const clear = clearSessionInStorage;
