import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import Dot from 'src/components/Dot';
import InputText from 'src/components/InputText';
import LogoSubtitle from 'src/components/LogoSubtitle';
import axiosInstance from 'src/services/axios';
import LoginWrapper from './LoginWrapper';

export const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const validPassword = password == cpassword && password.length >= 8;

  const handleReset = useCallback(() => {
    setLoading(true);
    const f = async (password: string, token: string) => {
      try {
        setLoading(true);
        const req = await axiosInstance.post(`/users/password/reset`, {
          password,
          token,
        });
        if (req.status >= 200 && req.status < 300) {
          navigate('/login');
        }
      } catch (err: any) {
        toast.error('Failed to reset password');
      } finally {
        setLoading(false);
      }
    };
    f(password, token!);
    return false;
  }, [password, token, navigate]);

  return (
    <>
      <div className="flex h-full min-h-screen w-full flex-col items-center justify-start bg-grey-900 md:justify-center md:bg-grey-1000">
        <LoginWrapper>
          <LogoSubtitle label="Enter your new password." />
          <div className="flex h-full w-full flex-grow flex-col justify-between md:flex-grow-0">
            <InputText
              name="password"
              size="medium"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <InputText
              name="cpassword"
              size="medium"
              label="Confirm Password"
              type="password"
              onChange={(e) => setCPassword(e.currentTarget.value)}
            />
            <Button
              type="submit"
              disabled={!validPassword}
              isLoading={isLoading}
              onClick={handleReset}
            >
              Submit
            </Button>
          </div>
          <Button preset="secondary" to="/login">
            Cancel
          </Button>
        </LoginWrapper>
        <div className="flex items-center justify-center pb-4 md:pt-4">
          <a
            className="text-xs text-grey-200 underline"
            href="https://livereach.ai/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <Dot size={2} />
          <a
            className="text-xs text-grey-200 underline"
            href="https://livereach.ai/resources/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <Dot size={2} />
          <a
            className="text-xs text-grey-200 underline"
            href="https://livereach.ai/demo"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
