import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

export enum AppModule {
  AUTH = 'auth',
  APP = 'app',
}

export type InitializationState = {
  module: AppModule;
  state: boolean;
};

export type AppState = {
  fromPath: string;
  initialized: InitializationState[];
};

const initialState: AppState = {
  fromPath: '',
  initialized: [
    {
      module: AppModule.AUTH,
      state: false,
    },
    {
      module: AppModule.APP,
      state: false,
    },
  ],
};

export const appInit = createAction('app/init');

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initializeAppModule(state, action: PayloadAction<AppModule>) {
      state.initialized = state.initialized.map((moduleInitState) => {
        if (action.payload === moduleInitState.module) {
          return { module: action.payload, state: true };
        }
        return moduleInitState;
      });
    },
    setFromPath(state, action: PayloadAction<string>) {
      state.fromPath = action.payload;
    },
  },
});

export const { initializeAppModule, setFromPath } = appSlice.actions;
export default appSlice;
