import configDev from '../config/dev';
import axiosInstance from '../services/axios';
import { Session } from './token';

const loginWithPassword = async (
  username: string,
  password: string,
  signal: AbortSignal,
): Promise<Session> => {
  const grant_type = 'password';
  const code = 'authorization_code';
  const realm = configDev.REALM;
  const { data } = await axiosInstance.post(
    '/login',
    {
      grant_type,
      username,
      password,
      realm,
      code,
    },
    {
      signal,
    },
  );

  return data;
};

export default loginWithPassword;
