import { RootState } from '../store';

export const selectSites = (state: RootState) => {
  return state.queueManangement.sites.data;
};

export const selectZones = (state: RootState) => state.queueManangement.zones.data;

export const selectSelectedSite = (state: RootState) => state.queueManangement.selectedSiteId;

export const selectZoneOverrides = (state: RootState) => state.queueManangement.zoneOverrides.data;

export const selectSitesLoading = (state: RootState) => state.queueManangement.sites.isLoading;

export const selectSitesFetched = (state: RootState) => !!state.queueManangement.sites.isFetched;

export const selectSitesFetchError = (state: RootState) => state.queueManangement.sites.error;

export const selectZonesLoading = (state: RootState) => state.queueManangement.zones.isLoading;

export const selectZonesFetched = (state: RootState) => !!state.queueManangement.zones.isFetched;

export const selectZonesFetchError = (state: RootState) => state.queueManangement.zones.error;

export const selectZoneOverridesLoading = (state: RootState) => state.queueManangement.zoneOverrides.isLoading;

export const selectZoneOverridesFetched = (state: RootState) => !!state.queueManangement.zoneOverrides.isFetched;

export const selectZoneOverridesFetchError = (state: RootState) => state.queueManangement.zoneOverrides.error;

export const selectLocations = (state: RootState) => state.queueManangement.locations;

export const selectLocationsLoading = (state: RootState) => state.queueManangement.locations.isLoading;

export const selectLocationsFetched = (state: RootState) => !!state.queueManangement.locations.isFetched;

export const selectLocationsFetchError = (state: RootState) => state.queueManangement.locations.error;

export const selectLiveWaitTimes = (state: RootState) => state.queueManangement.liveWaitTimes.data;

export const selectLiveWaitTimesLoading = (state: RootState) => state.queueManangement.liveWaitTimes.isLoading;

export const selectLiveWaitTimesFetched = (state: RootState) => !!state.queueManangement.liveWaitTimes.isFetched;

export const selectLiveWaitTimesFetchError = (state: RootState) => state.queueManangement.liveWaitTimes.error;

export const selectLiveLaneStatus = (state: RootState) => state.queueManangement.liveLaneStatus.data;

export const selectLiveLaneStatusLoading = (state: RootState) => state.queueManangement.liveLaneStatus.isLoading;

export const selectLiveLaneStatusFetched = (state: RootState) => !!state.queueManangement.liveLaneStatus.isFetched;

export const selectLiveLaneStatusFetchError = (state: RootState) => state.queueManangement.liveLaneStatus.error;