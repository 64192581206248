import { ReactNode, createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Url from 'url-parse';
import { useAppDispatch, useAppSelector } from 'src/store';
import { selectSession } from './authSelectors';
import { SessionState, login, logout as logoutAction } from './authSlice';

type AuthContextType = {
  session?: SessionState;
  logout: VoidFunction;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthContext = createContext<AuthContextType>({
  session: undefined,
  logout: () => void 0,
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const session = useAppSelector(selectSession);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = () => {
    navigate(`/`);
    dispatch(logoutAction());
  };

  // @TODO: Consider moving to authListener
  useEffect(() => {
    const url = new Url(window.location.toString(), true);
    const code = url.query['code'];
    if (code) {
      dispatch(login({ codeSSO: code }));
    }
  }, [dispatch]);

  const value = { session, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
