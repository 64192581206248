import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import LaneForm from "./LaneForm"
import VestibuleList from "./VestibuleList";
import { selectLiveLaneStatusFetched, selectLiveWaitTimesFetched, selectSelectedSite, selectSites, selectSitesFetched, selectZoneOverridesFetched, selectZonesFetched } from "./queueManagementSelectors";
import { fetchZones, fetchZoneOverrides, setSelectedSiteId, fetchLiveWaitTimes, fetchLiveLaneStatus } from "./queueManagementSlice";
// import Loader from "src/components/Loader";
import Dropdown from "src/components/Dropdown";
import Button from "src/components/Button";
import { logout } from "src/auth/authSlice";
import PageHeader from "src/components/PageHeader";
import LoadingContainer from "src/components/LoadingContainer";
import useMobile from "src/hooks/useMobile";


const QueueManagementHeader = () => {

  const dispatch = useAppDispatch();
  const isMobile = useMobile();
  // const selectedSiteId = useAppSelector(selectSelectedSite);
  // const sites = useAppSelector(selectSites);

  if (isMobile) {
    return (
      <PageHeader title="Queue Management"/>
    );
  }

  return (
    <PageHeader title="Queue Management">
      <div className="mx-4 my-4 flex flex-col items-center gap-2 md:mx-10 md:my-0 md:flex-row">
        <Button
          className="mb-0 h-10 w-full md:w-48"
          onClick={() => dispatch(logout())}
        >
          Logout
        </Button>
      </div>
    </PageHeader>
  );
};

const QueueManagement = () => {
  const dispatch = useAppDispatch();
  const selectedSiteId = useAppSelector(selectSelectedSite);
  const sitesFetched = useAppSelector(selectSitesFetched);
  const zonesFetched = useAppSelector(selectZonesFetched);
  const zoneOverridesFetched = useAppSelector(selectZoneOverridesFetched);
  const liveWaitsFetched = useAppSelector(selectLiveWaitTimesFetched);
  const liveLaneStatusFetched = useAppSelector(selectLiveLaneStatusFetched);
  const sites = useAppSelector(selectSites);
  const isMobile = useMobile();
  const overrideSites = [4533];
  // console.log(sites);

  useEffect(() => {
    if (sitesFetched && selectedSiteId !== undefined) {
      if (!zonesFetched) {
        dispatch(fetchZones(selectedSiteId))
      }
      if (!zoneOverridesFetched) {
        dispatch(fetchZoneOverrides(selectedSiteId))
      }
    }
  }, [sitesFetched, selectedSiteId, dispatch, zonesFetched, zoneOverridesFetched]);

  useEffect(() => {
    if (zoneOverridesFetched) {
      if (!liveWaitsFetched) {
        dispatch(fetchLiveWaitTimes())
      }

      if (!liveLaneStatusFetched) {
        dispatch(fetchLiveLaneStatus())
      }
    }
  }, [zoneOverridesFetched, liveWaitsFetched, liveLaneStatusFetched, dispatch]);

  if (!sitesFetched) {
    return <LoadingContainer isLoading={true} className="flex-grow"/>

  }

  return (
    <div className="p-0 xl:p-10 flex flex-col">
      <QueueManagementHeader />
      <div className="w-full md:w-[500px] p-4 md:p-10">
        <Dropdown
          options={sites.filter(x => overrideSites.includes(x.id)).map((site: any) => ({
            label: site.name,
            value: `${site.id}`,
          }))}
          onChange={(value: any) => {
            dispatch(setSelectedSiteId(parseInt(value)));
            // onChange('transport', value);
            // console.log(value);
          }}
          label={
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-grey-200">
              Checkpoint:&nbsp;
              <span className="text-white">{sites.length > 0 ? sites.filter((site: any) => site.id === selectedSiteId)[0].name : ''}</span>
            </div>
          }
          value={`${selectedSiteId}`}
        />
      </div>
      <div>
        <LoadingContainer isLoading={!zonesFetched || !zoneOverridesFetched}>
        {/* {(zonesFetched && zoneOverridesFetched) ? ( */}
        <div>
          <div className="">
            <LaneForm />
          </div>
          <div className="">
            <VestibuleList />
          </div>
          { isMobile && (
          <div className="mx-4 my-4 flex flex-col items-center gap-2 md:mx-10 md:my-0 md:flex-row">
            <Button
              className="mb-0 h-10 w-full md:w-48"
              onClick={() => dispatch(logout())}
            >
              Logout
            </Button>
          </div>
          )}
        </div>
        </LoadingContainer>
        {/* ): (<Loader />)} */}
      </div>
    </div>
    // </LoadingContainer>
  );
};

export default QueueManagement;