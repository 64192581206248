import axios from 'axios';
import dev from '../config/dev';
import { Session } from './token';

const loginWith2FA = async (
  code: string,
  access_token: string,
  signal: AbortSignal,
): Promise<Session> => {
  const { data } = await axios.post(
    dev.BASE_URL + '/login/verify_2fa',
    {
      code,
    },
    {
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
      signal,
    },
  );

  return data;
};

export default loginWith2FA;
