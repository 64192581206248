import { SVGProps } from 'react';

const SvgSuccess16 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8ZM7 11.4 3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4Z"
      fill="currentColor"
    />
  </svg>
);

SvgSuccess16.defaultProps = {
  width: 16,
  height: 16,
};
export default SvgSuccess16;
