import { SVGProps } from 'react';

const SvgSearch16 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="transparent" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 13.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM14.5 14.5 12 12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgSearch16.defaultProps = {
  width: 16,
  height: 16,
};
export default SvgSearch16;
