import clsx from 'clsx';
import { ReactNode } from 'react';
import Loader from './Loader';

type LoadingContainerProps = {
  isLoading: boolean;
  children?: ReactNode;
  className?: string;
  center?: boolean;
  unsetZIndex?: boolean;
};

const LoadingContainer = ({
  isLoading,
  children,
  className,
  center,
  unsetZIndex,
}: LoadingContainerProps) => {
  return (
    <div className={clsx(className, 'relative h-full')}>
      <div
        className={clsx(
          { hidden: !isLoading },
          { 'z-loader': !unsetZIndex },
          'absolute flex min-h-full w-full items-center justify-center opacity-80',
        )}
      >
        <div className={clsx({ 'fixed top-1/2': center })}>
          <Loader preset="rotatingLines" color="#FF9903" />
        </div>
      </div>
      {children}
    </div>
  );
};

export default LoadingContainer;
