type HrefProps = {
  className: string;
  href: string;
  label?: string;
};

const Href = (props: HrefProps) => {
  const { label } = props;
  return <a {...props}>{label}</a>;
};

export default Href;

Href.defaultProps = {
  className: 'text-sm font-normal text-orange-600 underline',
  href: '#',
};
