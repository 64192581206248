import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
// import { selectSelectedSite, selectSites, selectSitesFetched, selectSitesLoading, selectZoneOverrides, selectZones, selectZonesFetched, selectZonesLoading } from './queueManagementSelectors';
import { selectZoneOverrides, selectZoneOverridesFetched, selectZones, selectZonesFetched } from './queueManagementSelectors';

import InputText from 'src/components/InputText';
import Checkbox from 'src/components/Checkbox';
import { ZoneOverride } from './types';
import SvgCheck16 from 'src/icons/Check16';
import IconButton from 'src/components/IconButton';
import SvgDelete16 from 'src/icons/Delete16';
import { closeLane, openLane, updateZoneOverride } from './queueManagementSlice';
import BoolToLabel from 'src/components/BoolToLabel';
import Button from 'src/components/Button';
// import clsx from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import { BasicOption } from 'src/components/Dropdown';
import Dropdown from 'src/components/Dropdown';
import axiosInstance from 'src/services/axios';
import { ApiResponse, QueryApiResponse } from 'src/services/types';
import { toast } from 'react-toastify';
import { selectSession } from 'src/auth/authSelectors';
import useMobile from 'src/hooks/useMobile';
import clsx from 'clsx';

function stringToExpiresAt(str: string) {
  const currentTime = new Date();
  const minutesToAdd = parseInt(str, 10);
  const newTime = new Date(currentTime.getTime() + minutesToAdd * 60000); // Convert minutes to milliseconds

  return newTime;
}

const LaneForm = () => {
  const [localOverrides, setLocalOverrides] = useState<ZoneOverride[]>([]);
  const [editing, setEditing] = useState<Set<number>>(new Set<number>());
  const [expiresMap, setExpiresMap] = useState<{[key: number]: string}>({});
  const expireOptions: BasicOption[] = useMemo(() => {
    return [
      { label: '-', value: '' },
      { label: '30 minutes', value: '30' },
      { label: '1 hour', value: '60' },
      { label: '3 hours', value: '180' },
      { label: '24 hours', value: '1440' },
    ];
  }, []);
  const dispatch = useAppDispatch();
  const authSession = useAppSelector(selectSession);
  
  const updateItem = async (item: ZoneOverride) => {
    // console.log(item);
    dispatch(updateZoneOverride(item));
    if (!item.manual_override && !item.closed) {
      try {
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(
          `/zones/${item.zone_id}/metrics/live?metrics=waitTime&zoneOverride=true`,
          { headers: { Authorization: `Bearer ${authSession?.access_token}` } },
        );
        const current_waittime = response.data.content.rows[0][0];
        setLocalOverrides((prev: ZoneOverride[]) => {
          const index = prev.findIndex((zo: ZoneOverride) => zo.id === item.id);
          if (index !== -1) {
            const updatedZo = { ...prev[index], current_waittime };
            const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
            return newOverrides;
          }
          return prev;
        });
      } catch {
        toast.error('failed to fetch zone wait time');
      }
    }
    setEditing((prev) => {
      const newSet = new Set(prev);
      newSet.delete(item.id);
      return newSet;
    });
  }

  const resetChanges = async (item: ZoneOverride) => {
    const index = zoneOverrides.findIndex((zo: ZoneOverride) => zo.zone_id === item.zone_id);
    if (index != -1) {
      if (!zoneOverrides[index].manual_override && !zoneOverrides[index].closed) {
        try {
          const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(
            `/zones/${item.zone_id}/metrics/live?metrics=waitTime&zoneOverride=true`,
            { headers: { Authorization: `Bearer ${authSession?.access_token}` } },
          );
          const current_waittime = response.data.content.rows[0][0];
          const i = localOverrides.findIndex((zo: ZoneOverride) => zo.id === item.id);
          setLocalOverrides((prev: ZoneOverride[]) => {
            const updatedZo = { ...zoneOverrides[index], current_waittime };
            const newOverrides = [...prev.slice(0, i), updatedZo, ...prev.slice(i + 1)];
            return newOverrides;
          });
        } catch {
          toast.error('failed to fetch zone wait time');
        }
      } else {
        setLocalOverrides((prev: ZoneOverride[]) => {
          const i = localOverrides.findIndex((zo: ZoneOverride) => zo.id === item.id);
          const updatedZo = zoneOverrides[index];
          const newOverrides = [...prev.slice(0, i), updatedZo, ...prev.slice(i + 1)];
          return newOverrides;
        });
      }
    }
    setEditing((prev) => {
      const newSet = new Set(prev);
      newSet.delete(item.id);
      return newSet;
    });
  }

  const onChange = async (item: ZoneOverride, key: keyof ZoneOverride, e: any) => {
    // console.log(e);
    setLocalOverrides((prev: ZoneOverride[]) => {
      const index = prev.findIndex((zo: ZoneOverride) => zo.id === item.id);
      if (index !== -1) {
        if (key === 'closed') {
          const updatedZo = { ...prev[index], [key]: e, manual_override: e };
          const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
          return newOverrides;
        } else {
          const updatedZo = { ...prev[index], [key]: e };
          const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
          return newOverrides;
        }
      }
      return prev;
    });
    if (key === 'closed' && e === true) {
      dispatch(closeLane(item.zone_id));
      setEditing((prev) => {
        const newSet = new Set(prev);
        newSet.delete(item.id);
        return newSet;
      });
    } else if (key === 'closed' && e === false) {
      dispatch(openLane(item.zone_id));
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // if (!item.manual_override && !item.closed) {
      try {
        const response = await axiosInstance.get<ApiResponse<QueryApiResponse>>(
          `/zones/${item.zone_id}/metrics/live?metrics=waitTime&zoneOverride=true`,
          { headers: { Authorization: `Bearer ${authSession?.access_token}` } },
        );
        const current_waittime = response.data.content.rows[0][0];
        setLocalOverrides((prev: ZoneOverride[]) => {
          const index = prev.findIndex((zo: ZoneOverride) => zo.id === item.id);
          if (index !== -1) {
            const updatedZo = { ...prev[index], current_waittime };
            const newOverrides = [...prev.slice(0, index), updatedZo, ...prev.slice(index + 1)];
            return newOverrides;
          }
          return prev;
        });
      } catch {
        toast.error('failed to fetch zone wait time');
      }
      // }
      setEditing((prev) => {
        const newSet = new Set(prev);
        newSet.delete(item.id);
        return newSet;
      });
    } else {
      setEditing((prev) => {
        const newSet = new Set(prev);
        newSet.add(item.id);
        return newSet;
      });
    }
  };

  const zones = useAppSelector(selectZones);
  const zoneOverrides = useAppSelector(selectZoneOverrides);
  const zonesFetched = useAppSelector(selectZonesFetched);

  const zoneOverridesFetched = useAppSelector(selectZoneOverridesFetched);

  const queueZones = useMemo(() => zones.filter((zone: any) => zone.is_one_way_queuing === true), [zones]);

  const onInputChange = (item: ZoneOverride, key: keyof ZoneOverride) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange(item, key, e.target.value ? parseInt(e.target.value): undefined);
  };

  const isMobile = useMobile();

  useEffect(() => {
    if (zoneOverridesFetched && queueZones.length > 0) {
      const tmpList: ZoneOverride[] = []
      const newMap: {[key: number]: string} = {};
      queueZones.forEach((zone: any) => {
        if (zoneOverrides.filter((override: any) => override.zone_id === zone.id).length !== 0) {
          const zo: ZoneOverride = {...zoneOverrides.filter((override: any) => override.zone_id === zone.id)[0]};
          
          // const zo = zoneOverrides.filter((override: any) => override.zone_id === zone.id)[0];
          if (zo.expires) {
            let timeLeft = (new Date(zo.expires).getTime() - new Date().getTime());
            if (timeLeft < 0) {
              zo.manual_override = false;
              zo.closed = false;
            }
            timeLeft = timeLeft / 60000;
            if (timeLeft > 0) {
              if (timeLeft < 30) {
                newMap[zo.id] = '30';
              }
              else if (timeLeft < 60) {
                newMap[zo.id] = '60';
              }
              else if (timeLeft < 180) {
                newMap[zo.id] = '180';
              }
              else if (timeLeft < 1440) {
                newMap[zo.id] = '1440';
              }
            }
          }
          tmpList.push(zo);
          }
        });
        setExpiresMap(newMap);
        setLocalOverrides(tmpList);
    }
  }, [zoneOverridesFetched, queueZones, zoneOverrides]);

  if (!zoneOverridesFetched || !zonesFetched) {
    return <></>;
  }

  return (
    <div className={clsx("p-2 xl:p-10 border-b border-grey-800")}>
      {( !isMobile && (
      <div className="xl:flex hidden flex-grow flex-col xl:flex-row justify-between 2xl:justify-start">
        <div className="mb-6 text-2xl font-medium text-white xl:text-3xl xl:w-1/6 2xl:w-[20%] flex justify-center md:justify-start">Queues</div>
        <div className="mb-6 text-2xl font-medium text-white xl:text-3xl xl:w-1/6 2xl:w-[50%] flex justify-center md:justify-start">Display</div>
        <div className="mb-6 text-2xl font-medium text-white xl:text-3xl xl:w-1/2 2xl:w-[40%] flex justify-center md:justify-start ">Manage</div>
      </div>
      ))}
      {( isMobile && (
        <div className="mb-6 text-2xl font-medium text-white w-full flex justify-center">Queues</div>
      ))}
      <div className="flex justify-between gap-2 flex-col text-white">
        {(localOverrides.length > 0 && zones.length > 0) && (
          <div className="flex flex-col gap-2">
            {localOverrides.map((zone: any, index: number) => (
              <div key={index} className="flex gap-4 xl:gap-2 xl:mb-6 flex-col xl:flex-row justify-between rounded-md border border-grey-700 p-4 xl:p-0 xl:border-none">
                <div className='flex gap-4 items-center xl:w-96 justify-between'>
                  <div className="flex flex-col gap-2 text-white justify-center">
                    <span>{zones.filter((z) => z.id === zone.zone_id)[0]?.name}</span>
                  </div>
                  <div className="flex flex-row gap-4 items-center">
                    <BoolToLabel
                        bool={!zone.closed}
                        labels={{ true: 'Open', false: 'Closed' }}
                    />
                  </div>
                </div>
                <div className='flex gap-4 items-center xl:w-1/12 justify-center'>
                {!zone.manual_override && !zone.closed && (
                <div className='flex text-2xl font-medium text-white xl:text-3xl'>
                  {isMobile && 'Display: '}
                  {zone.current_waittime ? Math.max(Math.round(zone.current_waittime - (zones.filter((z) => z.id === zone.zone_id)[0]?.zone_config?.confidence_bounds || 2)),0) : ''} - {zone.current_waittime ? Math.round(zone.current_waittime + (zones.filter((z) => z.id === zone.zone_id)[0]?.zone_config?.confidence_bounds || 2)) : ''}
                </div>
                )}
                {zone.manual_override && !zone.closed && (
                <div className='flex text-2xl font-medium text-white xl:text-3xl'>
                  {isMobile && 'Display: '}
                  {zone.override_waittime ? Math.max(Math.round(zone.override_waittime - (zones.filter((z) => z.id === zone.zone_id)[0]?.zone_config?.confidence_bounds || 2)),0) : ''} - {zone.override_waittime ? Math.round(zone.override_waittime + (zones.filter((z) => z.id === zone.zone_id)[0]?.zone_config?.confidence_bounds || 2)) : ''}
                </div>
                )}
                {zone.closed && (
                  <div className='flex text-2xl font-medium text-red-700 xl:text-3xl'>
                    {isMobile && 'Display: '}
                      Closed
                  </div>
                )}
                </div>
                <div className='flex gap-4 xl:gap-2 flex-col xl:flex-row items-center justify-between'>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className="flex flex-row items-center">
                      {!zone.closed ? (
                        <Button className="h-10 w-full xl:w-24" preset="primary" name="closed" onClick={() => onChange(zone, 'closed', true)}>
                          Close
                        </Button>
                      ) : (
                        <Button className="h-10 w-full xl:w-24" preset="primary" name="closed" onClick={() => onChange(zone, 'closed', false)}>
                          Open
                        </Button>
                      )}
                    </div>
                    <div className='flex gap-4 flex-col xl:flex-row'>
                      <Checkbox name="manual_override" label='Override?' labelRight disabled={zone.closed} checked={zone.manual_override} onChange={({ target: { checked } }) => {!zone.closed && onChange(zone, 'manual_override',checked);}} />
                    </div>
                  </div>
                  <div className='flex gap-4 flex-row'>
                    <InputText type='number' className='w-16 xl:w-24' noMargin disabled={!zone.manual_override || zone.closed} name="override_waittime" label='Time' value={zone.override_waittime} onChange={onInputChange(zone, 'override_waittime')} inlineLabel/>
                      
                    <div className="w-56 self-center h-full">
                      <Dropdown
                        options={expireOptions}
                        disabled={!zone.manual_override}
                        // withPortal
                        onChange={(value: any) => {
                          setExpiresMap((prev) => {
                            const newMap = { ...prev };
                            newMap[zone.id] = value;
                            return newMap;
                          });
                          if (value === '') {
                            onChange(zone, 'expires', undefined);
                            return;
                          }
                          onChange(zone, 'expires', stringToExpiresAt(value));
                        }}
                        title={zone.expires ? formatInTimeZone(zone.expires, zones.filter((z) => z.id === zone.zone_id)[0].timezone, 'PPp'): '-'}
                        label={
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap text-grey-200">
                              <span className='flex'>Valid Until: {zone.expires ? formatInTimeZone(zone.expires, zones.filter((z) => z.id === zone.zone_id)[0].timezone, 'p'): '-'}</span>
                            {/* <span className="text-white">Expires In: {expiresMap[zone.id] ? expireOptions.filter(x => x.value === expiresMap[zone.id])[0].label : '-'}</span> */}
                          </div>
                        }
                        value={`${expiresMap[zone.id] || ''}`}
                      />
                    </div>
                  </div>
                  { (!isMobile || ( isMobile && editing.has(zone.id))) && (
                    <div className="flex gap-2 flex-row items-center justify-center">
                      <IconButton
                        onClick={() => resetChanges(zone)}
                        disabled={!editing.has(zone.id)}
                        icon={<SvgDelete16 />}
                        type="button"
                      />
                      <IconButton
                        onClick={() => updateItem(zone)}
                        disabled={!editing.has(zone.id)}
                        className="bg-orange-600 disabled:bg-grey-800 disabled:text-grey-300"
                        icon={<SvgCheck16 className="text-grey-1000" />}
                        type="button"                      
                      />
                  </div>
                  )}
                </div>
                {/* {(isMobile) && ( <Divider />)} */}
              </div>
            ))}
          </div>
          
        )}
      </div>
    </div>
  );
}

export default LaneForm;