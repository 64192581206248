export enum LoginFields {
  username = 'username',
  password = 'password',
  isRememberMe = 'isRememberMe',
}

export enum DigitFields {
  digit1 = 'digit1',
  digit2 = 'digit2',
  digit3 = 'digit3',
  digit4 = 'digit4',
  digit5 = 'digit5',
  digit6 = 'digit6',
}

export type LoginValues = {
  [LoginFields.isRememberMe]: boolean;
  [LoginFields.username]: string;
  [LoginFields.password]: string;
};

export type DigitValues = {
  [DigitFields.digit1]: string;
  [DigitFields.digit2]: string;
  [DigitFields.digit3]: string;
  [DigitFields.digit4]: string;
  [DigitFields.digit5]: string;
  [DigitFields.digit6]: string;
};
