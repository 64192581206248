import { SVGProps } from 'react';

const SvgArrowDown16 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m2 5 6 6 6-6"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

SvgArrowDown16.defaultProps = {
  width: 16,
  height: 16,
};
export default SvgArrowDown16;
