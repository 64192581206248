import dev from 'src/config/dev';
import ADFSSAML from '../assets/images/saml/ADFSSAML.svg';
import ADPOIDC from '../assets/images/saml/ADPOIDC.svg';
import Auth0SAML from '../assets/images/saml/Auth0SAML.svg';
import AzureSAML from '../assets/images/saml/AzureSAML.svg';
import CASSAML from '../assets/images/saml/CASSAML.svg';
import ClassLinkSAML from '../assets/images/saml/ClassLinkSAML.svg';
import CloudflareSAML from '../assets/images/saml/CloudflareSAML.svg';
import CyberArkSAML from '../assets/images/saml/CyberArkSAML.svg';
import DuoSAML from '../assets/images/saml/DuoSAML.svg';
import GenericOIDC from '../assets/images/saml/GenericOIDC.svg';
import GenericSAML from '../assets/images/saml/GenericSAML.svg';
import GoogleSAML from '../assets/images/saml/GoogleSAML.svg';
import JumpCloudSAML from '../assets/images/saml/JumpCloudSAML.svg';
import KeycloakSAML from '../assets/images/saml/KeycloakSAML.svg';
import LastPassSAML from '../assets/images/saml/LastPassSAML.svg';
import MiniOrangeSAML from '../assets/images/saml/MiniOrangeSAML.svg';
import NetIqSAML from '../assets/images/saml/NetIqSAML.svg';
import OktaSAML from '../assets/images/saml/OktaSAML.svg';
import OneLoginSAML from '../assets/images/saml/OneLoginSAML.svg';
import OracleSAML from '../assets/images/saml/OracleSAML.svg';
import PingFederateSAML from '../assets/images/saml/PingFederateSAML.svg';
import PingOneSAML from '../assets/images/saml/PingOneSAML.svg';
import SalesforceSAML from '../assets/images/saml/SalesforceSAML.svg';
import SimpleSamlPhpSAML from '../assets/images/saml/SimpleSamlPhpSAML.svg';
import VMwareSAML from '../assets/images/saml/VMwareSAML.svg';
import axiosInstance from '../services/axios';
import { ApiResponse } from 'src/services/types';
import { SSOMethod } from './authSlice';

export type ProviderKinds =
  | 'OktaSAML'
  | 'ADFSSAML'
  | 'ADPOIDC'
  | 'Auth0SAML'
  | 'AzureSAML'
  | 'CASSAML'
  | 'ClassLinkSAML'
  | 'CloudflareSAML'
  | 'CyberArkSAML'
  | 'DuoSAML'
  | 'GenericOIDC'
  | 'GenericSAML'
  | 'GoogleSAML'
  | 'JumpCloudSAML'
  | 'KeycloakSAML'
  | 'LastPassSAML'
  | 'MiniOrangeSAML'
  | 'NetIqSAML'
  | 'OneLoginSAML'
  | 'OracleSAML'
  | 'PingFederateSAML'
  | 'PingOneSAML'
  | 'SalesforceSAML'
  | 'SimpleSamlPhpSAML'
  | 'VMwareSAML';

type SSOProviders = Record<ProviderKinds, { src: string; kind: ProviderKinds }>;

export const PROVIDERS: SSOProviders = {
  OktaSAML: { src: OktaSAML, kind: 'OktaSAML' },
  ADFSSAML: { src: ADFSSAML, kind: 'ADFSSAML' },
  ADPOIDC: { src: ADPOIDC, kind: 'ADPOIDC' },
  Auth0SAML: { src: Auth0SAML, kind: 'Auth0SAML' },
  AzureSAML: { src: AzureSAML, kind: 'AzureSAML' },
  CASSAML: { src: CASSAML, kind: 'CASSAML' },
  ClassLinkSAML: { src: ClassLinkSAML, kind: 'ClassLinkSAML' },
  CloudflareSAML: { src: CloudflareSAML, kind: 'CloudflareSAML' },
  CyberArkSAML: { src: CyberArkSAML, kind: 'CyberArkSAML' },
  DuoSAML: { src: DuoSAML, kind: 'DuoSAML' },
  GenericOIDC: { src: GenericOIDC, kind: 'GenericOIDC' },
  GenericSAML: { src: GenericSAML, kind: 'GenericSAML' },
  GoogleSAML: { src: GoogleSAML, kind: 'GoogleSAML' },
  JumpCloudSAML: { src: JumpCloudSAML, kind: 'JumpCloudSAML' },
  KeycloakSAML: { src: KeycloakSAML, kind: 'KeycloakSAML' },
  LastPassSAML: { src: LastPassSAML, kind: 'LastPassSAML' },
  MiniOrangeSAML: { src: MiniOrangeSAML, kind: 'MiniOrangeSAML' },
  NetIqSAML: { src: NetIqSAML, kind: 'NetIqSAML' },
  OneLoginSAML: { src: OneLoginSAML, kind: 'OneLoginSAML' },
  OracleSAML: { src: OracleSAML, kind: 'OracleSAML' },
  PingFederateSAML: { src: PingFederateSAML, kind: 'PingFederateSAML' },
  PingOneSAML: { src: PingOneSAML, kind: 'PingOneSAML' },
  SalesforceSAML: { src: SalesforceSAML, kind: 'SalesforceSAML' },
  SimpleSamlPhpSAML: { src: SimpleSamlPhpSAML, kind: 'SimpleSamlPhpSAML' },
  VMwareSAML: { src: VMwareSAML, kind: 'VMwareSAML' },
};

const loginMethods = async (signal: AbortController['signal']): Promise<SSOMethod[]> => {
  const { data } = await axiosInstance.get<ApiResponse<SSOMethod[]>>('/login/methods', {
    params: { realm: dev.REALM },
    signal,
  });

  return data.content;
};

export default loginMethods;
