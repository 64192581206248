import { RootState } from 'src/store';
import { AppModule } from './appSlice';

export const isAppLoaded = (state: RootState) => {
  return state.app.initialized.every(({ state }) => state === true);
};

export const isModuleLoaded = (state: RootState, module: AppModule) => {
  return state.app.initialized.find((appInitState) => appInitState.module === module)?.state;
};

export const selectFromPath = (state: RootState) => state.app.fromPath;
