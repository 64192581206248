import { Oval, RotatingLines } from 'react-loader-spinner';

type LoaderProps = {
  preset: 'oval' | 'rotatingLines';
  color: string;
  width?: number;
};

const Loader = ({ preset, color, width }: LoaderProps) => {
  return (
    <>
      {
        {
          oval: (
            <Oval strokeWidth="4" secondaryColor="#CC7900" color={color} height={16} width={16} />
          ),
          rotatingLines: (
            <RotatingLines width={`${width ? width : '32'}`} strokeWidth="2" strokeColor={color} />
          ),
        }[preset]
      }
    </>
  );
};

export default Loader;

Loader.defaultProps = {
  preset: 'oval',
  color: '#B2B2B2',
};
