import SVGDanger16 from '../icons/Danger16';

const Error = ({ message }: { message?: string }) => {
  const visibilityClass = message ? 'block' : 'hidden';
  return (
    <div className={`${visibilityClass} flex items-center pt-2.5 align-middle text-red-600`}>
      <SVGDanger16 />
      <span className="ml-1 text-sm">{message}</span>
    </div>
  );
};

export default Error;
