import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import {clearApiResult, ApiResult, ApiResponse, QueryApiResponse } from '../services/types';
import { Site, Zone, ZoneOverride, Location } from './types';

type SliceState = {
  locations: ApiResult<Location[]>;
  sites: ApiResult<Site[]>;
  zones: ApiResult<Zone[]>;
  zoneOverrides: ApiResult<ZoneOverride[]>;
  selectedSiteId?: number;
  liveWaitTimes: ApiResult<QueryApiResponse>;
  liveLaneStatus: ApiResult<QueryApiResponse>;
};

const initialState: SliceState = {
  locations: clearApiResult([]),
  sites: clearApiResult([]),
  zones: clearApiResult([]),
  zoneOverrides: clearApiResult([]),
  selectedSiteId: undefined,
  liveWaitTimes: clearApiResult({ columns: [], rows: [] }),
  liveLaneStatus: clearApiResult({ columns: [], rows: [] })
};

const queueManagementSlice = createSlice({
  name: 'queueManangement',
  initialState,
  reducers: {
    locationsFetching(state) {
      state.locations.isLoading = true;
      state.locations.isFetched = false;
    },

    locationsFetched(state, action: PayloadAction<ApiResponse<Location[]>>) {
      state.locations.data = action.payload.content;
      state.locations.isLoading = false;
      state.locations.isFetched = true;
    },

    locationsFetchError(state, action: PayloadAction<string>) {
      state.locations.error = action.payload;
      state.locations.isFetched = true;
      state.locations.isLoading = false; 
    },

    sitesFetching(state) {
      state.sites.isLoading = true;
      state.sites.isFetched = false;
    },

    sitesFetched(state, action: PayloadAction<ApiResponse<Site[]>>) {
      state.sites.data = action.payload.content;
      state.sites.isLoading = false;
      state.sites.isFetched = true;
    },

    sitesFetchError(state, action: PayloadAction<string>) {
      state.sites.error = action.payload;
      state.sites.isFetched = true;
      state.sites.isLoading = false; 
    },

    zonesFetching(state) {
      state.zones.isLoading = true;
      state.zones.isFetched = false;
    },

    zonesFetched(state, action: PayloadAction<ApiResponse<Zone[]>>) {
      state.zones.data = action.payload.content;
      state.zones.isLoading = false;
      state.zones.isFetched = true;
    },

    zonesFetchError(state, action: PayloadAction<string>) {
      state.zones.error = action.payload;
      state.zones.isFetched = true;
      state.zones.isLoading = false; 
    },

    zoneOverridesFetching(state) {
      state.zoneOverrides.isLoading = true;
      state.zoneOverrides.isFetched = false;
    },

    zoneOverridesFetched(state, action: PayloadAction<ApiResponse<ZoneOverride[]>>) {
      state.zoneOverrides.data = action.payload.content;
      state.zoneOverrides.isLoading = false;
      state.zoneOverrides.isFetched = true;
    },

    zoneOverridesFetchError(state, action: PayloadAction<string>) {
      state.zoneOverrides.error = action.payload;
      state.zoneOverrides.isFetched = true;
      state.zoneOverrides.isLoading = false; 
    },

    setSelectedSiteId(state, action: PayloadAction<number>) {
      state.selectedSiteId = action.payload;
      state.zones.data = [];
      state.zoneOverrides.data = [];
      state.zones.isFetched = false;
      state.zoneOverrides.isFetched = false;
    },

    liveWaitTimesFetching(state) {
      state.liveWaitTimes.isLoading = true;
      state.liveWaitTimes.isFetched = false;
    },

    zoneWaitTimesFetched(state, action: PayloadAction<[QueryApiResponse, number]>) {
      const data = action.payload[0];
      if(data.rows.length > 0) {
        data.rows.forEach((row) => {
          // const zoneId = row[0];
          const currentWaitTime = row[0];
          state.zoneOverrides.data.forEach((zoneOverride) => {
            if (zoneOverride.zone_id === action.payload[1]) {
              if (currentWaitTime != null) {
                zoneOverride.current_waittime = currentWaitTime;
              }
            }
          });
        });
      }
    },

    zoneLaneStatusFetched(state, action: PayloadAction<[QueryApiResponse, number]>) {
      const data = action.payload[0];
      if(data.rows.length > 0) {
        data.rows.forEach((row) => {
          const currentOccupancy = row[0];
          const currentStatus = row[1];
          state.zoneOverrides.data.forEach((zoneOverride) => {
            if (zoneOverride.zone_id === action.payload[1]) {
              if (currentOccupancy != null) {
                zoneOverride.current_occupancy = currentOccupancy;
              }
              if (currentStatus != null) {
                zoneOverride.current_status = currentStatus;
              }
            }
          });
        });
      }
    },

    liveWaitTimesFetched(state, action: PayloadAction<QueryApiResponse>) {
      const data = action.payload;
      if(data.rows.length > 0) {
        data.rows.forEach((row) => {
          const zoneId = row[0];
          const currentWaitTime = row[1];
          state.zoneOverrides.data.forEach((zoneOverride) => {
            if (zoneOverride.zone_id === zoneId) {
              if (currentWaitTime != null) {
                zoneOverride.current_waittime = currentWaitTime;
              }
            }
          });
        });
      }
      state.liveWaitTimes.data = data;
      state.liveWaitTimes.isLoading = false;
      state.liveWaitTimes.isFetched = true;
    },

    liveWaitTimesFetchError(state, action: PayloadAction<string>) {
      state.liveWaitTimes.error = action.payload;
      state.liveWaitTimes.isFetched = true;
      state.liveWaitTimes.isLoading = false;
    },

    liveLaneStatusFetching(state) {
      state.liveLaneStatus.isLoading = true;
      state.liveLaneStatus.isFetched = false;
    },

    liveLaneStatusFetched(state, action: PayloadAction<QueryApiResponse>) {
      state.liveLaneStatus.data = action.payload;
      const data = action.payload;
      if(data.rows.length > 0) {
        data.rows.forEach((row) => {
          const zoneId = row[0];
          const currentOccupancy = row[1];
          const currentStatus = row[2];
          state.zoneOverrides.data.forEach((zoneOverride) => {
            if (zoneOverride.zone_id === zoneId) {
              if (currentOccupancy != null) {
                zoneOverride.current_occupancy = currentOccupancy;
              }
              if (currentStatus != null) {
                zoneOverride.current_status = currentStatus;
              }
            }
          });
        });
      }
      state.liveLaneStatus.isLoading = false;
      state.liveLaneStatus.isFetched = true;
    },

    liveLaneStatusFetchError(state, action: PayloadAction<string>) {
      state.liveLaneStatus.error = action.payload;
      state.liveLaneStatus.isFetched = true;
      state.liveLaneStatus.isLoading = false; 
    },
  }
});

export const {
  sitesFetching,
  sitesFetched,
  sitesFetchError,
  zonesFetching,
  zonesFetched,
  zonesFetchError,
  zoneOverridesFetching,
  zoneOverridesFetched,
  zoneOverridesFetchError,
  locationsFetching,
  locationsFetched,
  locationsFetchError,
  setSelectedSiteId,
  liveWaitTimesFetching,
  liveWaitTimesFetched,
  liveWaitTimesFetchError,
  liveLaneStatusFetching,
  liveLaneStatusFetched,
  liveLaneStatusFetchError,
  zoneWaitTimesFetched,
  zoneLaneStatusFetched,
} = queueManagementSlice.actions;

export const fetchLocations = createAction('queueManangement/fetchLocations');
export const fetchSites = createAction('queueManangement/fetchSites');
export const fetchZones = createAction<number>('queueManangement/fetchZones');
export const fetchZoneOverrides = createAction<number>('queueManangement/fetchZoneOverrides');
export const updateZoneOverride = createAction<ZoneOverride>('queueManangement/updateZoneOverride');
export const closeLane = createAction<number>('queueManangement/closeLane');
export const openLane = createAction<number>('queueManangement/openLane');
export const fetchLiveWaitTimes = createAction('queueManangement/fetchLiveWaitTimes');
export const fetchLiveLaneStatus = createAction('queueManangement/fetchLiveLaneStatus');
export const updateZoneWaitTime = createAction<number>('queueManangement/updateZoneWaitTime');
export const updateZoneLaneStatus = createAction<number>('queueManangement/updateZoneLaneStatus');

export default queueManagementSlice;

