import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import addQueueManagementListeners from './waittimes/queueManagementListeners';
import addAppListeners from 'src/redux/appListeners';
import type { AppDispatch, RootState } from './store';
import addAuthListeners from './auth/authListeners';

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const listenerMiddleware = createListenerMiddleware();
export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

[
  addAppListeners,
  addQueueManagementListeners,
  addAuthListeners,
].forEach((listeners) => listeners(startAppListening));
