/* LEGAL INFO
 * Modified version of useMedia hook
 * source: https://usehooks.com/useOnClickOutside/
 */
import { RefObject, useEffect } from 'react';

type ListenerEvent = globalThis.MouseEvent | globalThis.TouchEvent;

function useOnClickOutside(ref: RefObject<HTMLElement>, handler: (event: ListenerEvent) => void) {
  useEffect(() => {
    const listener = (event: ListenerEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
