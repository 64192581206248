import clsx from 'clsx';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { overrideTailwindClasses } from 'tailwind-override';
import useOnClickOutside from '../hooks/useOnClickOutside';
import ArrowDown16 from '../icons/ArrowDown16';
import SvgCheck12 from '../icons/Check12';
import SvgSearch16 from '../icons/Search16';
import Loader from './Loader';
import Portal from './Portal';

type AllOrNone<T> = Required<T> | Partial<Record<keyof T, undefined>>;

const VERTICAL_BORDERS = 2;
const VERTICAL_PADDINGS = 0.5;
const ITEM_HEIGHT = 44;
const OFFSET_HEIGHT = 4;

export type BasicOption<T = string> = {
  label: string;
  value: T;
  icon?: ReactNode;
};

export type ThumbnailOption<T = string> = {
  label: string;
  value: T;
  thumb: string;
  thumbFull?: string;
  badge?: 'ai';
  description?: string;
  icon?: ReactNode;
};

export type DropdownProps<T> = {
  withPortal?: boolean;
  value: string;
  label: ReactNode;
  isLoading?: boolean;
  title?: string;
  className?: string;
  listClassName?: string;
  onChange?: (optionValue: T) => void;
  hasError?: boolean;
  badges?: boolean;
  maxItems?: number;
  buttonClassName?: string;
  searchPlaceholder?: string;
  disabled?: boolean;
  options: BasicOption<T>[];
} & AllOrNone<{
  searchValue: string;
  onSearch: Dispatch<SetStateAction<string>> | ((searchValue: string) => void);
}>

const Dropdown = <T,>({
  label,
  options,
  value,
  searchValue,
  isLoading,
  className,
  listClassName,
  onSearch,
  onChange,
  title,
  hasError,
  maxItems = 3.5,
  withPortal,
  buttonClassName,
  disabled,
  searchPlaceholder,
}: DropdownProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isDropdown, setIsDropdown] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const itemHeight = ITEM_HEIGHT;

  const [anchor, setAnchor] = useState<{ top?: number; left?: number; width?: number }>();
  const [isLeft, setIsLeft] = useState(false);

  const setPosition = useCallback(() => {
    const el = dropdownRef.current?.getBoundingClientRect();
    withPortal &&
      setAnchor({
        top: el ? el?.top + el?.height + OFFSET_HEIGHT : 0,
        left: el?.left,
        width: el?.width,
      });
    setIsLeft(el ? el?.left < el?.width : false);
  }, [withPortal]);

  useOnClickOutside(portalRef, ({ target }) => {
    target !== buttonRef.current && setIsDropdown(false);
  });

  useEffect(() => {
    const ev = () => withPortal && setIsDropdown(false);
    window.addEventListener('resize', ev);

    return () => {
      window.removeEventListener('resize', ev);
    };
  }, [withPortal]);

  const dropdown = (
    <div
      ref={portalRef}
      style={withPortal ? { ...anchor } : undefined}
      className={clsx(
        `absolute ${
          !withPortal && 'right-0 top-[3rem] '
        } z-portal w-full rounded-md border border-grey-700 bg-grey-800 p-1`,
        isLeft ? 'left-0' : 'right-0',
        listClassName,
      )}
    >
      {searchValue !== undefined && onSearch !== undefined && (
        <div
          className={`relative hidden items-center rounded-md border border-grey-700 bg-grey-1000 py-2.5 pl-4 md:flex ${
            isFocus && 'border-orange-600'
          }`}
        >
          <SvgSearch16 className={`mr-3.5 ${isFocus ? 'text-white' : 'text-grey-200'}`} />
          <input
            placeholder={searchPlaceholder || 'Search...'}
            value={searchValue}
            onChange={({ target: { value } }) => onSearch(value)}
            onBlur={() => setIsFocus(false)}
            onFocus={() => setIsFocus(true)}
            className="w-full bg-grey-1000 text-white caret-orange-600"
          />
        </div>
      )}
      {isLoading && (
        <div className="flex justify-center py-2.5">
          <Loader />
        </div>
      )}
      {isDropdown && !!options.length && (
        <div
          className={`overflow-auto overflow-x-hidden`}
          style={{
            maxHeight: `calc(${VERTICAL_BORDERS}px + ${VERTICAL_PADDINGS}rem + ${
              maxItems * itemHeight
            }px)`,
          }}
        >
          {!isLoading &&
            (options.map((option) => (
              <button
                key={`option_${option.value}`}
                onClick={() => {
                  onChange && onChange(option.value);
                  setIsDropdown(false);
                }}
                className="flex w-full items-center justify-between rounded-md p-2.5 text-white hover:bg-grey-700"
              >
                <div className="flex w-full flex-row items-center gap-2">
                  {option.icon}
                  <div
                    className="overflow-hidden text-ellipsis whitespace-nowrap"
                    title={option.label}
                  >
                    {option.label}
                  </div>
                </div>
                {option.value === value && <SvgCheck12 className="shrink-0 text-orange-600" />}
              </button>
            )))}
        </div>
      )}
    </div>
  );

  return (
    <div
      ref={dropdownRef}
      className={overrideTailwindClasses(
        clsx('relative cursor-pointer rounded-md bg-grey-800', className),
      )}
    >
      {withPortal && isDropdown && (
        <div className="fixed left-0 top-0 z-background h-screen w-screen" />
      )}
      <button
        ref={buttonRef}
        type="button"
        className={clsx(
          'flex h-10 w-full items-center justify-between gap-2 rounded-md border border-grey-800 py-2 px-4 text-white shadow-preset1',
          buttonClassName,
          hasError && 'border-red-600',
        )}
        title={title}
        onClick={() => {
          if (!disabled) {
            setPosition();
            setIsDropdown(!isDropdown);
          }
        }}
      >
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">{label}</div>
        {!disabled && (
          <ArrowDown16 className={`${isDropdown && 'rotate-180'} shrink-0 text-white`} />
        )}
      </button>
      {isDropdown && <>{withPortal ? <Portal>{dropdown}</Portal> : dropdown}</>}
    </div>
  );
};

export default Dropdown;

Dropdown.defaultProps = {
  isSearchable: false,
};
